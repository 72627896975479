import React from "react";
import Button from '../../02_atoms/Button';
import urlFetch from "../../00_utilities/connectivity/urlFetch";
import {Context} from "../../00_utilities/context";
import { ReactComponent as CartIcon } from '../../../icons/cart.svg';
import { upsertToCartObject } from '../../00_utilities/connectivity/updateCart';

const AddToCartButton = (props) => {
    const { product, count, updatePopupState } = props;
    const [loading, setLoading] = React.useState(false);
    const context = React.useContext(Context);

    if (!context.userEmail) {
        return <div>No user e-mail given</div>
    }

    const addToCart = () => {
        const data = upsertToCartObject(product.id, count, context);
        setLoading(true);
        urlFetch('/api/carts/update', { successCallback: (response) => { context.updateCart(response); updatePopupState(true); setLoading(false);  } }, null, 'POST', null, { email: context.userEmail, data }, context.token);
    }

    return (
        <Button loading={loading} inverted>
            <span className='interactive-link' onClick={addToCart}>Add to cart <CartIcon/></span>
        </Button>
    )
}

export default AddToCartButton;
