import React from 'react';
import styled from 'styled-components';
import QuantitySelect from '../../02_atoms/QuantitySelect';
import {Context} from "../../00_utilities/context";
import AddToCartButton from "../AddToCartButton";

const StyledProductTableRow = styled('tr')`
`;

function ProductTableRow (props) {
    const { product, setAddedProduct } = props;
    const context = React.useContext(Context);
    const [count, setCount] = React.useState(1);

    const updateQuantityCount = (e) => {
        let value = e.currentTarget.value;

        if (value !== '' && Number(value) < 1) {
            value = 1;
        }

        setCount(value);
    }

    const resetQuantityCount = (e) => {
        let value = e.currentTarget.value;

        if (value === '') {
            setCount(1);
        }
    }

    const updatePopupState = () => {
        context.setPopupActive(true);
        setAddedProduct(product);
    }

    return (
        <StyledProductTableRow className='product-table-row'>
            <td>
                <span>{product.external_id}</span>
            </td>
            <td>
                <span>{product.GTIN}</span>
            </td>
            <td>
                <span>{product.title}</span>
            </td>
            <td>
                <span>€ {product.price}</span>
            </td>
            <td>
                <div>
                    <QuantitySelect value={count} blurHandler={resetQuantityCount} changeHandler={updateQuantityCount}/>
                    <AddToCartButton product={product} count={count} updatePopupState={updatePopupState}/>
                </div>
            </td>
        </StyledProductTableRow>
    )
}

export default ProductTableRow;
