import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import BrainlabNowW05MediumWoff from '../../../fonts/BrainlabNowW05-Medium.woff';
import BrainlabNowW05MediumWoff2 from '../../../fonts/BrainlabNowW05-Medium.woff2';
import BrainlabNowW05RegularWoff from '../../../fonts/BrainlabNowW05-Regular.woff';
import BrainlabNowW05RegularWoff2 from '../../../fonts/BrainlabNowW05-Regular.woff2';
import BrainlabNowW05BoldWoff from '../../../fonts/BrainlabNowW05-Bold.woff';
import BrainlabNowW05BoldWoff2 from '../../../fonts/BrainlabNowW05-Bold.woff2';

const calculationValues = {
    fontSizes: {
        base: 1,
    },
}

const themeSettings = {
    colors: {
        white: "#fff",
        black: "#434549",
        whiteSmoke: "#F5F5F5",
        pink: "#EE2375",
        pinkDark: "#C10E56",
        darkGrey: "#434549",
        midGrey: "#A1A2A4",
        lightGrey: "#C0C0C0",
        tableGrey: "#E8E8E8",
        randomGrey: "#8F8C8F",
        randomBorderGrey: "#D5D3D4",
        anotherRandomBorderGrey: "#5E5D61",
        yetAnotherRandomBorderGrey: "#DFDEDF",
        inputFocusBorderColor: "#1381D4"
    },
    fonts: ["sans-serif", "Roboto"],
    fontSizes: {
        small: `${calculationValues.fontSizes.base * 0.875}rem`,
        medium: `${calculationValues.fontSizes.base}rem`,
        large: `${calculationValues.fontSizes.base * 1.4}rem`,
        h1: {
            sm: `${calculationValues.fontSizes.base * 2}rem`,
            lg: `${calculationValues.fontSizes.base * 2.5}rem`,
        },
        h2: {
            sm: `${calculationValues.fontSizes.base * 1.625}rem`,
            lg: `${calculationValues.fontSizes.base * 2}rem`,
        },
        h3: {
            sm: `${calculationValues.fontSizes.base * 1.625}rem`,
            lg: `${calculationValues.fontSizes.base * 1.625}rem`,
        },
        h4: {
            sm: `${calculationValues.fontSizes.base * 1.25}rem`,
            lg: `${calculationValues.fontSizes.base * 1.25}rem`,
        },
        h5: {
            sm: `${calculationValues.fontSizes.base * 1.25}rem`,
            lg: `${calculationValues.fontSizes.base * 1.25}rem`,
        },
        h6: {
            sm: `${calculationValues.fontSizes.base * 1.25}rem`,
            lg: `${calculationValues.fontSizes.base * 1.25}rem`,
        },
    },
    lineHeights: {
        default: 1.5,
        headlines: 1.2,
    },
    fontWeights: {
        regular: 400,
        medium: 500,
        bold: 600,
    },
    headlineMarginsBottom: {
        h1: `${calculationValues.fontSizes.base * 1.2}rem`,
        h2: `${calculationValues.fontSizes.base * 1.2}rem`,
        h3: `${calculationValues.fontSizes.base}rem`,
    },
    breakpoints: {
        xs: {
            breakpoint: 0,
            maxWidth: 345,
        },
        ssm: {
            breakpoint: 384,
            maxWidth: 384,
        },
        sm: {
            breakpoint: 576,
            maxWidth: 576,
        },
        md: {
            breakpoint: 768,
            maxWidth: 768,
        },
        lg: {
            breakpoint: 992,
            maxWidth: 992,
        },
        xl: {
            breakpoint: 1215,
            maxWidth: 1092,
        },
        xxl: {
            breakpoint: 1500,
            maxWidth: 1445,
        },
        max: {
            breakpoint: 1920,
            maxWidth: 1920,
        },
    }
};

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "BrainlabNowW05";
        font-weight: 500;
        src: url(${BrainlabNowW05MediumWoff2}) format('woff2'),
        url(${BrainlabNowW05MediumWoff}) format('woff');
        font-display: swap;
    }
    
    @font-face {
        font-family: "BrainlabNowW05";
        font-weight: 600;
        src: url(${BrainlabNowW05BoldWoff2}) format('woff2'),
        url(${BrainlabNowW05BoldWoff}) format('woff');
        font-display: swap;
    }
    
    @font-face {
        font-family: "BrainlabNowW05";
        font-weight: 400;
        src: url(${BrainlabNowW05RegularWoff2}) format('woff2'),
        url(${BrainlabNowW05RegularWoff}) format('woff');
        font-display: swap;
    }
    
    body {
        margin: 0;
        background-color: ${props => props.theme.colors.white};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        ${props => {
            if (props.popupActive) {
                return `
                    height: 100vh;
                    overflow-y: hidden;
            `
            }
        }}
    }
    
    p {
        letter-spacing: 0;
    }
    
    body,
    input,
    select {
        font-family: 'BrainlabNowW05', sans-serif;
        color: ${props => props.theme.colors.black};
        font-size: ${props => props.theme.fontSizes.medium};
        line-height: ${props => props.theme.lineHeights.default};
        font-weight: 400;
        letter-spacing: 0.03rem;
    }
    
    h1,
    .h1 {
        font-size: ${props => props.theme.fontSizes.h1.sm};
        margin-bottom: ${props => props.theme.headlineMarginsBottom.h1};
        font-weight: ${props => props.theme.fontWeights.medium};
    
        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: ${props => props.theme.fontSizes.h1.lg};
        }
    }
    
    h2,
    .h2 {
        font-size: ${props => props.theme.fontSizes.h2.sm};
        line-height: ${props => props.theme.lineHeights.h2};
        margin-bottom: ${props => props.theme.headlineMarginsBottom.h2};
        font-weight: ${props => props.theme.fontWeights.medium};
    
        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: ${props => props.theme.fontSizes.h2.lg};
        }
    }
    
    h3,
    .h3 {
        font-size: ${props => props.theme.fontSizes.h3.sm};
        line-height: ${props => props.theme.lineHeights.h3};
        margin-bottom: ${props => props.theme.headlineMarginsBottom.h3};
        font-weight: ${props => props.theme.fontWeights.medium};
    
        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: ${props => props.theme.fontSizes.h3.lg};
        }
    }
    
    h4,
    .h4 {
        font-size: ${props => props.theme.fontSizes.h4.sm};
        font-weight: ${props => props.theme.fontWeights.medium};
    
        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: ${props => props.theme.fontSizes.h4.lg};
        }
    }
    
    h5,
    .h5 {
        font-size: ${props => props.theme.fontSizes.h5.sm};
        font-weight: ${props => props.theme.fontWeights.medium};
    
        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: ${props => props.theme.fontSizes.h5.lg};
        }
    }
    
    h6,
    .h6 {
        font-size: ${props => props.theme.fontSizes.h6.sm};
        font-weight: ${props => props.theme.fontWeights.medium};
    
        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: ${props => props.theme.fontSizes.h6.lg};
        }
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        letter-spacing: 0;
        margin-top: 0;
        line-height: ${props => props.theme.lineHeights.headlines};
    }
    
    a {
        font-weight: ${props => props.theme.fontWeights.medium};
        color: ${props => props.theme.colors.pink};
        text-decoration: none;
        
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
`

const Theme = ({popupActive, children}) => (
    <>
        <GlobalStyle theme={themeSettings} popupActive={popupActive}/>
        <ThemeProvider theme={themeSettings}>{children}</ThemeProvider>
    </>
);

export { themeSettings };
export default Theme;
