import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon} from '../../../icons/empty-arrow-down.svg';
import { ReactComponent as ReceivedIcon} from '../../../icons/order-received.svg';
import { ReactComponent as InProgressIcon} from '../../../icons/order-inprogress.svg';
import { ReactComponent as FulfilledIcon} from '../../../icons/order-fulfilled.svg';
import Button from "../../02_atoms/Button";
import urlFetch from "../../00_utilities/connectivity/urlFetch";
import {Context} from "../../00_utilities/context";
import getFloatFromPriceString from '../../00_utilities/functions/getFloatFromPriceString';
import printPriceInGermanFormatString from '../../00_utilities/functions/printPriceInGermanFormatString';

const StyledOrderElement = styled('div')`
    margin-bottom: 40px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 20px;
    }
`;

const StyledOrderElementHeader = styled('div')`
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.whiteSmoke};
    padding: 15px;
    
    .arrow-down-icon {
        fill: ${props => props.theme.colors.pink};
        width: 21px;
        height: 21px;
        position: absolute;
        top: ${props => props.active ? '25px' : '20px'};
        right: 20px;
        transform: rotate(${props => props.active ? '180deg' : '0deg'});

        path {
            fill: ${props => props.theme.colors.pink};
        }

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            top: ${props => props.active ? '50px' : '40px'};
            right: 30px;
            width: 31px;
            height: 31px;
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        padding: 30px 80px 10px 30px;
    }
    
    &:hover {
        cursor: pointer;
    }
`;

const StyledHeaderItemParagraph = styled('p')`
    margin: 0;
    font-size: ${props => props.highlighted ? '20px' : '16px'};
    
    &:first-child {
        font-weight: ${props => props.theme.fontWeights.medium};
    }
`;

const StyledOrderElementHeaderItem = styled('div')`
    &:nth-child(1) {
        width: 100%;
        min-width: 285px;
        margin-bottom: 20px;
    }
    
    &:nth-child(2) {
        height: 80px;
        width: 100%;
        min-width: 145px;
        min-height: 50px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    &:nth-child(3) {
        width: 50%;
        min-width: 135px;
    }
    
    &:nth-child(4) {
        width: 50%;
        min-width: 135px;
        text-align: right;
    }
    
    &:nth-child(5) {
        width: 100%;
        min-width: 125px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        height: auto;
        margin-right: 20px;
        margin-bottom: 20px;

        &:nth-child(1) {
            width: 20%
        }

        &:nth-child(2) {
            width: 15%;
            height: auto;
        }

        &:nth-child(3) {
            width: 15%;
        }

        &:nth-child(4) {
            width: 15%;
            text-align: left;
        }

        &:nth-child(5) {
            width: 15%;
            margin-top: 0;
            margin-right: 0;
        }
    }
    
    @media (min-width: ${props => props.theme.breakpoints.xl.breakpoint}px) {
        margin-right: 10px;
    }
`;

const StyledOrderElementBody = styled('div')`
    padding: 40px 15px;
    border: 1px solid ${props => props.theme.colors.randomBorderGrey};
    border-top: none;
    display: ${props => props.active ? 'block' : 'none'};

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        padding: 40px 30px;
    }
`;

const StyledOrderStateMarksContainer = styled('div')`
    display: inline-flex;
    align-items: flex-end;
    height: 100%;
`;

const StyledOrderStateMark = styled('div')`
    border: 1px solid ${props => props.theme.colors.black};
    width: 14px;
    height: 14px;
    background-color: ${props => props.active ? props.theme.colors.black : 'transparent'};
    position: relative;
    display: flex;
    justify-content: center;
    
    svg {
        position: absolute;
        bottom: 26px;
        width: 42px;
        height: 42px;
        
        path {
            fill: ${props => props.theme.colors.black};
        }

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            width: 34px;
            height: 34px;
            bottom: 25px;
        }
    }

    &:not(:last-child) {
        margin-right: 25px;

        &:after {
            content: '';
            position: absolute;
            left: 14px;
            top: 7px;
            background-color: ${props => props.theme.colors.black};
            width: 26px;
            height: 1px;
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 10px;
        height: 10px;

        &:not(:last-child) {
            margin-right: 15px;

            &:after {
                content: '';
                position: absolute;
                left: 10px;
                top: 4px;
                background-color: ${props => props.theme.colors.black};
                width: 16px;
                height: 1px;
            }
        }
    }
`;

const StyledOrderItemElement = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    border-bottom: 1px solid black;
    
    &:first-child {
        padding-top: 0;
    }
    
    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledOrderItemImageContainer = styled('div')`
    overflow: hidden;
    margin-bottom: 40px;
    width: 100%;
    height: 230px;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 400px;
        margin-right: 40px;
        margin-bottom: 0;
    }
`;

const StyledOrderItemTextContainer = styled('div')`
    p {
        margin-top: 0;
        margin-bottom: 5px;
    }
    
    p:first-child {
        font-size: 26px;
        font-weight: ${props => props.theme.fontWeights.medium};
    }
    
    p:nth-child(2) {
        font-size: 20px;
        font-weight: ${props => props.theme.fontWeights.medium};
    }
    
    p:nth-child(3) {
        font-size: 13px;
        color: #B4B1B3;
    }
    
    p:nth-child(4) {
        font-size: 16px;
        font-weight: ${props => props.theme.fontWeights.medium};
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        p:first-child {
            font-size: 24px;
        }

        p:nth-child(2) {
            font-size: 20px;
            font-weight: ${props => props.theme.fontWeights.medium};
        }

        p:nth-child(3) {
            font-size: 16px;
            color: ${props => props.theme.colors.black};
        }

        p:nth-child(4) {
            font-size: 16px;
            font-weight: ${props => props.theme.fontWeights.medium};
        }
    }
    
    &.order-item--shipping {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        
        p:first-child {
            font-size: 24px;
        }
        
        p:nth-child(2) {
            span:first-child {
                font-size: 14px;
                font-weight: ${props => props.theme.fontWeights.regular};
            }
            
            span:nth-child(2) {
                font-size: 24px;
            }
        }
    }
`;

const StyledMetaInfo = styled('div')`
    font-size: 14px;
    margin-bottom: 10px;
    
    span + span {
        margin-left: 20px;
    }
`;

function OrderElement (props) {
    const { order, initiallyActive, setPopupMessage, reorderAvailableProducts, setPreparedReorder } = props;
    const context = React.useContext(Context);
    const items = order.items ? Object.values(order.items) : [];
    const [active, setActive] = React.useState(initiallyActive);
    const [checkReorderLoading, setCheckReorderLoading] = React.useState(false);
    const orderItemElements = [];
    let orderSumString = order ? order.sum : "0";
    const orderStates = [
        {
            title: 'Requested',
            description: 'Your request has been placed',
            icon: <ReceivedIcon/>
        },
        {
            title: 'Confirmed',
            description: 'Your order has been confirmed',
            icon: <ReceivedIcon/>
        },
        {
            title: 'In Progress',
            description: 'Your order is in progress',
            icon: <InProgressIcon/>
        },
        {
            title: 'In Delivery',
            description: 'Your order is in delivery',
            icon: <InProgressIcon/>
        },
        {
            title: 'Fulfilled',
            description: 'Your order has been fulfilled',
            icon: <FulfilledIcon/>
        }
    ];

    const getOrderProgressLevel = (state) => {
        const states = ['requested', 'confirmed', 'inprogress', 'indelivery', 'fulfilled'];

        return state !== null ? states.indexOf(state) : 0;
    }

    const orderStateOptions = orderStates[getOrderProgressLevel(order.state)];

    const getOrderStateElement = (state) => {
        const progressLevel = getOrderProgressLevel(state);
        const orderStateMarks = [];

        for (let i = 0; i < orderStates.length; i += 1) {
            const active = i === progressLevel;

            const icon = active ? orderStateOptions.icon : null;
            const orderStateMark = <StyledOrderStateMark
                key={`order-state-mark-${i}`}
                className='order-state-mark'
                active={active}>{icon}</StyledOrderStateMark>;

            orderStateMarks.push(orderStateMark);
        }

        return <StyledOrderStateMarksContainer>{orderStateMarks}</StyledOrderStateMarksContainer>
    }

    const getAvailableProducts = () => {
        const items = order.items ? Object.values(order.items) : [];
        const availableProducts = [];

        for (let i = 0; i < items.length; i += 1) {
            const orderItem = items[i];
            const product = orderItem.product;

            if (product.is_shipping_cost) {
                continue;
            }

            if (!product.is_active) {
                continue;
            }

            availableProducts.push(product);
        }

        return availableProducts;
    }

    const checkReorderSuccessCallback = (response) => {
        const availableProducts = getAvailableProducts();
        setCheckReorderLoading(false);

        if (response.message && availableProducts.length > 0) {
            setPreparedReorder(order);
            setPopupMessage(response.message);

            return;
        }

        if (response.message && availableProducts.length === 0) {
            setPopupMessage(response.message);

            return;
        }

        reorderAvailableProducts(order);
    }

    const reorderClickHandler = (e) => {
        e.stopPropagation();

        if (!order || !order.id) {
            return;
        }

        setCheckReorderLoading(true);

        urlFetch('/api/orders/check-reorder', { successCallback: checkReorderSuccessCallback }, null, 'POST', null, { email: context.userEmail, id: order.id }, context.token);
    }

    for (let j = 0; j < items.length; j += 1) {
        const orderItem = items[j];

        if (orderItem.product.is_shipping_cost) {
            const orderSumFloat = getFloatFromPriceString(orderSumString);
            const shippingCostFloat = getFloatFromPriceString(orderItem.product.price);

            const orderSumFloatWithoutShipping = orderSumFloat - shippingCostFloat;
            orderSumString = printPriceInGermanFormatString(orderSumFloatWithoutShipping);

            continue;
        }

        orderItemElements.push(
            <StyledOrderItemElement key={`order-item-${j}`}>
                <StyledOrderItemImageContainer dangerouslySetInnerHTML={{ __html: orderItem.product.image.list }}></StyledOrderItemImageContainer>
                <StyledOrderItemTextContainer>
                    <StyledMetaInfo>
                        { orderItem.product.external_id && <span>Art.Nr: {orderItem.product.external_id}</span> }
                        { orderItem.product.GTIN && <span>GTIN: {orderItem.product.GTIN}</span> }
                    </StyledMetaInfo>

                    <p>{orderItem.product.title}</p>
                    <p>{orderItem.product.price} € (per packaging unit) without VAT</p>
                    <p>Quantity</p>
                    <p>{orderItem.count}</p>
                </StyledOrderItemTextContainer>
            </StyledOrderItemElement>
        )
    }

    return (
        <StyledOrderElement>
            <StyledOrderElementHeader active={active} fulfilled={getOrderProgressLevel(order.state) === 2} onClick={() => { setActive(!active) }}>
                <StyledOrderElementHeaderItem>
                    <StyledHeaderItemParagraph highlighted>Request {order.number}</StyledHeaderItemParagraph>
                    <StyledHeaderItemParagraph>{orderStateOptions.description}</StyledHeaderItemParagraph>
                </StyledOrderElementHeaderItem>

                <StyledOrderElementHeaderItem>
                    { getOrderStateElement(order.state) }
                </StyledOrderElementHeaderItem>

                <StyledOrderElementHeaderItem>
                    <StyledHeaderItemParagraph>Request placed</StyledHeaderItemParagraph>
                    <StyledHeaderItemParagraph>{order.date}</StyledHeaderItemParagraph>
                </StyledOrderElementHeaderItem>

                <StyledOrderElementHeaderItem>
                    <StyledHeaderItemParagraph>Subtotal products</StyledHeaderItemParagraph>
                    <StyledHeaderItemParagraph>{ orderSumString } €</StyledHeaderItemParagraph>
                </StyledOrderElementHeaderItem>

                <StyledOrderElementHeaderItem>
                    <Button inverted clickHandler={reorderClickHandler} loading={checkReorderLoading}>Add to cart</Button>
                </StyledOrderElementHeaderItem>

                <ArrowDownIcon className='arrow-down-icon'/>
            </StyledOrderElementHeader>

            <StyledOrderElementBody active={active}>
                { orderItemElements }
            </StyledOrderElementBody>
        </StyledOrderElement>
    )
}

export default OrderElement;
