import DefaultPage from '../../05_templates/Default';
import React from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { useParams } from 'react-router-dom';
import {Context} from "../../00_utilities/context";
import HeadlessContentElements from "../../01_base/HeadlessContentElements";

function HeadlessContent() {
    const { key } = useParams();
    const context = React.useContext(Context);
    const [headlessContentLoaded, setHeadlessContentLoaded] = React.useState(false);
    const [headlessContent, setHeadlessContent] = React.useState(null);
    const lastTitle = document.title;

    React.useEffect(() => {
        urlFetch(`/api/headless-content/${key}`, { successCallback }, null, 'POST', null, { email: context.userEmail });

        return () => {
            document.title = lastTitle;
        }
    }, [headlessContentLoaded]); // eslint-disable-line

    const successCallback = (response) => {
        setHeadlessContentLoaded(true);
        setHeadlessContent(response);

        updateDocumentTitle(response);

        // Necessary to load usercentrics embeddings into headless content elements
        requestAnimationFrame(() => {
            if (window.UC_UI) {
                window.UC_UI.restartEmbeddings();
            }
        });
    }

    const updateDocumentTitle = (response) => {
        if (response === null || !response.hasOwnProperty('title') ) {
            return null;
        }

        document.title = response.title;
    }

    return (
        <DefaultPage loading={!headlessContentLoaded}>
            <HeadlessContentElements headlessContent={headlessContent} withComponentContainer/>
        </DefaultPage>
    );
}

export default HeadlessContent;
