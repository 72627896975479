import React from 'react';
import FormContainer from '../../01_base/layout/FormContainer';
import FormInput from '../../02_atoms/FormInput';

function QuoteCheckoutInformation (props) {
    const isEditing = true;
    const {changeHandler, getValueByKey} = props;

    return (
        <div className={'quote-checkout-information'}>
            <FormContainer>
                <FormInput name={'quoteEmail'} value={getValueByKey('email')} label='E-mail address purchasing department' changeHandler={changeHandler} isEditing={isEditing} componentWidth='100%' showIsRequired/>
                <FormInput name={'quoteContactPerson'} value={getValueByKey('contact')} label='Purchasing contact (Full name)'  changeHandler={changeHandler} isEditing={isEditing} componentWidth='100%' showIsRequired/>
                <FormInput name={'quoteNotes'} value={getValueByKey('notes')} label='Additional notes' changeHandler={changeHandler} isEditing={isEditing} componentWidth='100%' isTextArea/>
                <div className='form-input-container form-input-container__pull-up'>* Required</div>
            </FormContainer>
        </div>
    )
}

export default QuoteCheckoutInformation;
