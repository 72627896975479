module.exports = (data, fileType, fileName) => {
    const blob = new Blob([data], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    a.style.display = 'none';

    document.body.appendChild(a);
    a.click();
    a.remove();

    URL.revokeObjectURL(url);
};
