import React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowDownIcon} from '../../../icons/empty-arrow-down.svg';
import LoadingIcon from '../../02_atoms/LoadingIcon';

const StyledLoadMoreContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.pink};
    font-weight: ${props => props.theme.fontWeights.medium};
`;

const StyledLoadMoreClickArea = styled('div')`
    width: 110px;
    
    &:hover {
        cursor: pointer;
    }
`;

const StyledLoadMoreTitle = styled('div')`
    text-align: center;
    margin-bottom: 10px;
`;

const StyledLoadMoreIconContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 40px;
    
    svg {
        position: absolute;
        width: 30px;
        height: 30px;
        
        path {
            fill: ${props => props.theme.colors.pink};
        }
    }
    
    .loading-icon {
        position: absolute;
    }
`;

function LoadMore(props) {
    const {loading, clickHandler, title = 'Discover more'} = props;

    return (
        <StyledLoadMoreContainer>
            <StyledLoadMoreClickArea onClick={clickHandler}>
                <StyledLoadMoreTitle>{title}</StyledLoadMoreTitle>
                <StyledLoadMoreIconContainer>
                    { loading && <LoadingIcon/>}
                    { !loading && <ArrowDownIcon/>}
                </StyledLoadMoreIconContainer>
            </StyledLoadMoreClickArea>
        </StyledLoadMoreContainer>
    );
}

export default LoadMore;
