import DefaultPage from '../../05_templates/Default';
import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import HomeLink from '../../02_atoms/HomeLink';
import ContentContainer from '../../01_base/layout/ContentContainer';
import FeedbackForm from "../../03_molecules/FeedbackForm";
import { ReactComponent as SubmittedIcon } from '../../../icons/save.svg';
import { ReactComponent as WhiteCheckIcon } from '../../../icons/save-white.svg';
import {Context} from "../../00_utilities/context";

const StyledSuccessBox = styled('div')`
    margin-bottom: 50px;
    
    .h1 {
        font-size: 32px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: 40px;
        }
    }
    
    .order-number {
        color: #B4B1B3;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 60px;
    }
`;

const StyledThankYou = styled('div')`
    display: flex;
    
    svg {
        width: 70px;
        height: 70px;
        margin-bottom: -15px;
        margin-top: -5px;
        margin-left: 5px;
        
        path {
            fill: ${props => props.theme.colors.pink};
        }
    }
    
    h1 > span {
        position: relative;
        display: inline-block;
    }
`;

const StyledIconOverlay = styled('span')`
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background: white;
    transition: width 1200ms;
    
    &.activated {
        width: 0;
    }
`;

const StyledWhatsNextBox = styled('div')`
    padding: 20px;
    background-color: ${props => props.theme.colors.whiteSmoke};
    margin-bottom: 50px;

    h3 {
        color: ${props => props.theme.colors.pink};
        margin-bottom: 30px;
    }
    
    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        padding: 40px;
        margin-bottom: 60px;
    }
`;

const StyledFeedbackFormContainer = styled('div')`
`;

const StyledOrderSteps = styled('div')`
    width: 100%;
    display: flex;
    margin-bottom: 50px;
    
    .order-steps__step-container {
        width: 25%;
        padding-bottom: 50px;
        display: flex;
        align-items: center;
        position: relative;
        
        &.order-steps__step-container--active {
            .order-steps__step-bubble {
                background-color: ${props => props.theme.colors.pink};
                width: 32px;
                height: 32px;

                @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
                    width: 42px;
                    height: 42px;
                }
                
                svg {
                    display: block;
                }
            }

            .order-steps__step-title {
                color: ${props => props.theme.colors.pink};
                font-weight: ${props => props.theme.fontWeights.medium};
            }
            
            .order-steps__step-line {
                background-color: ${props => props.theme.colors.pink};
            }
            
            &.order-steps__step-container--last-active {
                .order-steps__step-line {
                    background-color: ${props => props.theme.colors.lightGrey};
                }

                .order-steps__step-text-container {
                    display: block;
                }
            }
        }
        
        &:last-child {
            .order-steps__step-line {
                display: none;
            }
        }
    }
    
    .order-steps__step-bubble {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: ${props => props.theme.colors.lightGrey};
        
        svg {
            width: 16px;
            height: 16px;
            display: none;
        }
    }
    
    .order-steps__step-line {
        height: 2px;
        width: 100%;
        background-color: ${props => props.theme.colors.lightGrey};
    }
    
    .order-steps__step-text-container {
        position: absolute;
        white-space: nowrap;
        display: none;
        bottom: 0;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            display: block;
        }
    }
    
    .order-steps__step-topline {
        color: #a9a9a9;
        font-size: 12px;
    }
    
    .order-steps__step-title {
        color: ${props => props.theme.colors.black};
        letter-spacing: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 60px;
    }
`;

const StyledOrderStepsInfo = styled('div')`
    max-width: 670px;
`;

const OrderSteps = () => {
    const steps = [
        {
            title: 'Product selection',
            active: true
        },
        {
            title: 'Quote request',
            active: true
        },
        {
            title: 'Place order',
            active: false
        },
        {
            title: 'Receive shipment',
            active: false
        },
    ];

    const stepNodes = [];

    for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        const title = step.title;
        const active = step.active;
        const lastActive = i < (steps.length - 1) && active && !(steps[i + 1].active);
        const number = i + 1;

        const nodeClass = `order-steps__step-container ${active ? 'order-steps__step-container--active' : ''} ${lastActive ? 'order-steps__step-container--last-active' : ''}`

        stepNodes.push(
            <div className={nodeClass} key={`order-step-${i}`}>
                <div className='order-steps__step-bubble'><WhiteCheckIcon/></div>
                <div className='order-steps__step-line'/>
                <div className='order-steps__step-text-container'>
                    <div className='order-steps__step-topline'>Step {number}</div>
                    <div className='order-steps__step-title'>{title}</div>
                </div>
            </div>
        )
    }

    return <StyledOrderSteps className='order-steps'>{stepNodes}</StyledOrderSteps>
}

function Success(props) {
    const hasHistory = 'history' in props;
    const hasHistoryState = hasHistory && typeof props.history.location.state !== 'undefined';
    const hasOrderNumber = hasHistoryState && typeof props.history.location.state.orderNumber !== 'undefined';
    const orderNumber = hasOrderNumber ? props.history.location.state.orderNumber : null;
    const iconOverlayRef = React.useRef(null);
    const [cartReloaded, setCartReloaded] = React.useState(false);
    const context = React.useContext(Context);

    React.useEffect(() => {
        if (!hasOrderNumber) {
            return;
        }

        if (!cartReloaded) {
            context.loadCart(() => { setCartReloaded(true); });
        }

        requestAnimationFrame(() => {
            const iconOverlay = iconOverlayRef.current;
            iconOverlay.classList.add('activated');
        });
    }, []); // eslint-disable-line

    return (
        <DefaultPage>
            {orderNumber === null && <Redirect to='/'/>}

            <ComponentContainer>
                <ContentContainer>
                    <HomeLink/>

                    <StyledSuccessBox>
                        <StyledThankYou>
                            <h1>Thank You! <span><StyledIconOverlay ref={iconOverlayRef}/><SubmittedIcon/></span></h1>
                        </StyledThankYou>

                        <p>Your quote will be processed and provided to your specified recipient via email.
                            <br/>This might take a few minutes.
                        </p>
                    </StyledSuccessBox>

                    <StyledWhatsNextBox>
                        <h3>What's next?</h3>

                        <OrderSteps/>

                        <StyledOrderStepsInfo>
                            <p>To get the requested products, your recipient needs to place an order to the requested quote. So far, no order is placed.</p>
                        </StyledOrderStepsInfo>
                    </StyledWhatsNextBox>

                    <StyledFeedbackFormContainer>
                        <FeedbackForm target='checkout'/>
                    </StyledFeedbackFormContainer>
                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default Success;
