module.exports = (dataObject) => {
    const columnTitles = dataObject['column_titles'];
    const predefinedColumnValues = dataObject['predefined_column_values'];
    const data = dataObject['data'];

    const additionalPrefixLineString = "sep=;";
    const columnTitlesString = Object.values(columnTitles).join(';');

    const dataColumnStrings = [];

    for (let i = 0; i < data.length; i++) {
        const dataEntry = data[i];
        let dataColumnString = '';

        for (let j = 0; j < columnTitles.length; j++) {
            const columnTitle = columnTitles[j];

            if (columnTitle in predefinedColumnValues) {
                dataColumnString += `${predefinedColumnValues[columnTitle]};`;

                continue;
            }

            if (columnTitle in dataEntry) {
                dataColumnString += `${dataEntry[columnTitle]};`;

                continue;
            }

            if (j === columnTitles.length - 1) {
                continue; // skip adding a semicolon at last position
            }

            dataColumnString += `;`;
        }

        dataColumnStrings.push(dataColumnString);
    }

    return [additionalPrefixLineString,columnTitlesString, ...dataColumnStrings].join('\n');
};
