import DefaultPage from '../../05_templates/Default';
import React from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import styled from 'styled-components';
import { Context } from '../../00_utilities/context';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import ProductCard from '../../03_molecules/ProductCard';
import ProductItemsContainer from '../../01_base/layout/ProductItemsContainer';
import LoadMore from '../../03_molecules/LoadMore';
import ProductListItem from "../../03_molecules/ProductListItem";
import ItemTypeListFilter from "../../03_molecules/ItemTypeListFilter";
import AddedProductPopup from "../../03_molecules/AddedProductPopup";
import ProductTableRow from "../../03_molecules/ProductTableRow";
import ProductItemsTable from "../../01_base/layout/ProductItemsTable";
import ArrowDownIcon from '../../../icons/empty-arrow-down-pink.svg';
import { ReactComponent as ViewModeIconList } from '../../../icons/view-mode-list.svg';
import { ReactComponent as ViewModeIconCard } from '../../../icons/view-mode-card.svg';
import { ReactComponent as ViewModeIconTable } from '../../../icons/view-mode-table.svg';
import FeedbackForm from "../../03_molecules/FeedbackForm";
import HeadlessContentElements from "../../01_base/HeadlessContentElements";
import ProductSearch from "../../03_molecules/ProductSearch";
import HCDPDownload from "../../03_molecules/HCDPDownload";

const VIEW_MODE_LIST = 'list';
const VIEW_MODE_CARD = 'card'
const VIEW_MODE_TABLE = 'table'

const VIEW_MODES = [
    VIEW_MODE_CARD,
    VIEW_MODE_TABLE,
    VIEW_MODE_LIST
];

const SORTING_TITLE_ASC = 'title_asc';
const SORTING_TITLE_DESC = 'title_desc';
const SORTING_EXTERNALID_ASC = 'externalId_asc';
const SORTING_EXTERNALID_DESC = 'externalId_desc';
const SORTING_DEFAULT = 'default';

const SORT_MODES = [
    SORTING_TITLE_ASC,
    SORTING_TITLE_DESC,
    SORTING_EXTERNALID_ASC,
    SORTING_EXTERNALID_DESC,
    // SORTING_DEFAULT
];

const StyledProductItemsContainer = styled(ProductItemsContainer)`
    margin-bottom: 60px;
`;

const StyledIntroBoxes = styled('div')`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledFeedbackFormContainer = styled('div')`
    margin-top: 60px;
    padding-top: 60px;
    border-top: 2px solid #DFDEDF;
`;

const StyledUserIntroBox = styled('div')`
    margin-bottom: 70px;
    box-sizing: border-box;
    width: 100%;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 75%;
        margin-right: 32px;
        margin-bottom: 0;
    }
    
    p {
        margin: 0;
    }
    
    p:first-child {
        margin-bottom: 5px;
    }
`;

const StyledSortAndFilterBar = styled('div')`
    margin-bottom: 10px;
    border-bottom: 1px solid ${props => props.theme.colors.black};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        flex-direction: row;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 20px;
    }
`;

const StyledSortInputsContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        margin-bottom: 0;
    }
    
    .hcdp-download {
        margin-right: 16px;
        
        .button {
            white-space: nowrap;
            padding: 4px 8px;
        }
    }
`;

const StyledSortInputElement = styled('div')`
    + div {
        margin-left: 16px;
    }
`;

const StyledProductSearchContainer = styled('div')`
    margin-bottom: 40px;
`;

const StyledListBody = styled('div')`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledListProductsContainer = styled('div')`
    width: 100%;
    margin-top: 10px;
`;

const StyledViewModeToggle = styled('div')`
    position: relative;
`;

const StyledViewModeSlider = styled('div')`
    width: 51px;
    height: 34px;
    background-color: #F5F5F5;
    border: 1px solid #DFDEDF;
    border-radius: 4px;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    transition: left 300ms;
`;

const StyledViewModeTogglesContainer = styled('div')`
    border: 1px solid #DFDEDF;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 34px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
`;

const StyledViewModeToggleItem = styled('div')`
    font-size: 0;
    height: 34px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    svg {
        width: 22px;
        height: 22px;
        fill: ${props => props.theme.colors.black};
        transition: fill 300ms;
    }

    &:hover {
        cursor: pointer;

        svg {
            fill: ${props => props.theme.colors.pinkDark};
        }
    }
    
    &::after {
        content: '';
        width: 1px;
        height: 24px;
        background-color: #DFDEDF;
        position: absolute;
        right: 0;
    }
    
    &:last-child {
        &::after {
            content: none;
        }
    }

    ${props => {
        if (props.isActive) {
            return `
                    svg {
                        fill: ${props.theme.colors.pink};
                    }
                    
                    &:hover {
                        svg {
                            fill: ${props.theme.colors.pink};
                        }
                    }
            `
        }
    }}
`;

const StyledSortSelect = styled('select')`
    background: url(${ArrowDownIcon}) no-repeat calc(100% - 16px) calc(50% - 2px);
    background-size: 12px;
    background-color: ${props => props.theme.colors.white};
    appearance: none;
    border: 1px solid #DFDEDF;
    border-radius: 4px;
    padding: 4px 40px 4px 22px;
    width: 100%;
    min-width: 152px;
    color: ${props => props.theme.colors.pink};
    position: relative;

    &.active,
    &:active,
    &:focus {
        background-color: ${props => props.theme.colors.white};
        background-size: 12px;
        color: ${props => props.theme.colors.pink};
        outline: 0;
    }

    &.warning {
        border-color: red;
    }

    &:hover {
        cursor: pointer;
        border-color: #DFDEDF;
        color: ${props => props.theme.colors.pinkDark};
    }
`;

const ITEM_TYPE_ALL = 'all';

function List() {
    const [products, setProducts] = React.useState([]);
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(0);
    const [currentItemType, setCurrentItemType] = React.useState(ITEM_TYPE_ALL);
    const [viewModeIndex, setViewModeIndex] = React.useState(0);
    const [sorting, setSorting] = React.useState(SORTING_TITLE_ASC);
    const [search, setSearch] = React.useState('');
    const [loadMore, setLoadMore] = React.useState(false);
    const [isLoadingMore, setIsLoadingMore] = React.useState(false);
    const [addedProduct, setAddedProduct] = React.useState(null);
    const [headlessContent, setHeadlessContent] = React.useState(null);
    const context = React.useContext(Context);
    const sliderRef = React.useRef(null);

    const setLoadMoreStates = (response) => {
        setOffset(response.offset);
        setLimit(response.limit);
        setLoadMore(response.load_more);
    }

    const productSuccessCallback = (response) => {
        setProducts(response.elements);
        setLoadMoreStates(response);
    }

    const productLoadMoreSuccessCallback = (response) => {
        const newProducts = [].concat(products, response.elements);
        setProducts(newProducts);
        setIsLoadingMore(false);

        setLoadMoreStates(response);
    }

    const headlessContentSuccessCallback = (response) => {
        setHeadlessContent(response);
    }

    React.useEffect(() => {
        urlFetch('/api/products', { successCallback: productSuccessCallback }, null, 'POST', { itemType: currentItemType, offset, sorting, search }, { email: context.userEmail }, context.token);

        if (headlessContent === null) {
            urlFetch(`/api/headless-content/intro`, { successCallback: headlessContentSuccessCallback }, null, 'POST', null, { email: context.userEmail });
        }
    }, [sorting, search]); // eslint-disable-line

    const filterProducts = (itemType = null) => {
        const queryParameters = itemType !== null ? { itemType: itemType, sorting, search } : { sorting, search };

        urlFetch('/api/products', { successCallback: (response) => { setCurrentItemType(itemType); productSuccessCallback(response); } }, null, 'POST', queryParameters, { email: context.userEmail }, context.token);
    }

    const loadMoreClickHandler = () => {
        setIsLoadingMore(true);
        const newOffset = parseInt(limit, 10) + parseInt(offset, 10);
        const queryParameters = { offset: newOffset, limit: 200, sorting, search };

        if (currentItemType !== null) {
            queryParameters.itemType = currentItemType;
        }

        urlFetch('/api/products', { successCallback: productLoadMoreSuccessCallback }, null, 'POST', queryParameters, { email: context.userEmail }, context.token);
    };

    const getProductList = () => {
        const productDivs = [];
        const selectedViewMode = VIEW_MODES[viewModeIndex];
        const includedTax = products.length > 0 ? products[0]['included_tax'] : 0;

        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            let productDiv = null;

            switch (selectedViewMode) {
                case VIEW_MODE_LIST: productDiv = <ProductListItem key={`product-${i}`} product={product} setAddedProduct={setAddedProduct}/>; break;
                case VIEW_MODE_CARD: productDiv = <ProductCard key={`product-${i}`} product={product} setAddedProduct={setAddedProduct}/>; break;
                case VIEW_MODE_TABLE: productDiv = <ProductTableRow key={`product-${i}`} product={product} setAddedProduct={setAddedProduct}/>; break;
                default: productDiv = <ProductListItem key={`product-${i}`} product={product} setAddedProduct={setAddedProduct}/>; break;
            }

            productDivs.push(productDiv)
        }

        if (selectedViewMode === VIEW_MODE_TABLE) {
            return <ProductItemsTable includedTax={includedTax}>{productDivs}</ProductItemsTable>;
        }

        return <StyledProductItemsContainer>{productDivs}</StyledProductItemsContainer>;
    }

    const getIconByViewMode = (key) => {
        switch (key) {
            case VIEW_MODE_LIST: return <ViewModeIconList/>;
            case VIEW_MODE_CARD: return <ViewModeIconCard/>;
            case VIEW_MODE_TABLE: return <ViewModeIconTable/>;
            default: return <ViewModeIconList/>;
        }
    }

    const setSliderLeft = (left) => {
        const sliderElement = sliderRef.current;
        sliderElement.style.left = `${left}px`;
        sliderElement.style.width = `${51 + (Math.floor(left/100))}px`;
    }

    const getViewModeToggle = () => {
        const toggleItems = [];

        for (let i = 0; i < VIEW_MODES.length; i++) {
            const viewMode = VIEW_MODES[i];
            const isActive = i === viewModeIndex;
            const icon = getIconByViewMode(viewMode);

            const toggleItem =
                <StyledViewModeToggleItem
                    key={`view-mode-toggle-item-${viewMode}`}
                    className={`view-mode-toggle-item-${viewMode}`}
                    onClick={() => { setSliderLeft(i*50); setViewModeIndex(i); }}
                    isActive={isActive}>
                    {icon}
                </StyledViewModeToggleItem>;

            toggleItems.push(toggleItem);
        }

        return <StyledViewModeToggle>
                <StyledViewModeSlider ref={sliderRef}></StyledViewModeSlider>
                <StyledViewModeTogglesContainer>{toggleItems}</StyledViewModeTogglesContainer>
            </StyledViewModeToggle>
    }

    const getSortModeTitleByKey = (key) => {
        switch (key) {
            case SORTING_TITLE_ASC: return 'Name (A-Z)';
            case SORTING_TITLE_DESC: return 'Name (Z-A)';
            case SORTING_EXTERNALID_ASC: return 'Art. No. (↑)';
            case SORTING_EXTERNALID_DESC: return 'Art. No. (↓)';
            case SORTING_DEFAULT: return 'Default (ID)';
            default: return 'Default (ID)';
        }
    }

    const getSortSelect = () => {
        const options = [];

        for (let i = 0; i < SORT_MODES.length; i++) {
            const sortMode = SORT_MODES[i];

            options.push(<option key={`sort-mode-option-${sortMode}`} value={sortMode}>{getSortModeTitleByKey(sortMode)}</option>);
        }

        return <StyledSortSelect value={sorting} onChange={(e) => { setOffset(0); setSorting(e.currentTarget.value); }}>{options}</StyledSortSelect>
    }

    const getNoSearchResult = () => {
        return <div>Sorry, your search didn't match any products. Try other search terms and/or combination of filters.</div>
    }

    const loading = products.length === 0 && search === '';

    return (
        <DefaultPage loading={loading} popupActive={!!addedProduct}>
            <ComponentContainer>
                { addedProduct && <AddedProductPopup product={addedProduct} setAddedProduct={setAddedProduct}/> }

                <ContentContainer>
                    <StyledIntroBoxes>
                        <StyledUserIntroBox>
                            <HeadlessContentElements headlessContent={headlessContent}/>
                        </StyledUserIntroBox>
                    </StyledIntroBoxes>
                    <div>
                        <StyledSortAndFilterBar>
                            <ItemTypeListFilter updateHandler={filterProducts} currentItemType={currentItemType}/>

                            <StyledSortInputsContainer>
                                <HCDPDownload/>
                                <StyledSortInputElement>{ getViewModeToggle() }</StyledSortInputElement>
                                <StyledSortInputElement>{ getSortSelect() }</StyledSortInputElement>
                            </StyledSortInputsContainer>
                        </StyledSortAndFilterBar>

                        <StyledProductSearchContainer>
                            <ProductSearch setSearch={(value) => { setOffset(0); setSearch(value); }}/>
                        </StyledProductSearchContainer>

                        <StyledListBody>
                            <StyledListProductsContainer>
                                { products.length !== 0 && getProductList() }
                                { search !== '' && products.length === 0 && getNoSearchResult() }
                            </StyledListProductsContainer>
                        </StyledListBody>

                        { loadMore && <LoadMore clickHandler={loadMoreClickHandler} loading={isLoadingMore} title={"Show all"}/> }
                    </div>

                    <StyledFeedbackFormContainer>
                        <FeedbackForm/>
                    </StyledFeedbackFormContainer>
                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default List;
