import React from 'react';
import styled from 'styled-components';
import QuantitySelect from '../../02_atoms/QuantitySelect';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { ReactComponent as DeleteIcon } from '../../../icons/trash.svg';
import { Context } from '../../00_utilities/context';
import updateCart from '../../00_utilities/connectivity/updateCart';
import LoadingIcon from '../../02_atoms/LoadingIcon';
import getFloatFromPriceString from '../../00_utilities/functions/getFloatFromPriceString';
import printPriceInGermanFormatString from '../../00_utilities/functions/printPriceInGermanFormatString';

const StyledCartElement = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    border-top: 2px solid ${props => props.theme.colors.randomBorderGrey};
    
    .cart-element__image-container {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
        position: relative;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            width: 33.3333%;
            margin-right: 40px;
            margin-bottom: 0;
        }
    }
    
    &:first-child {
        border-top: none;
    }
    
    &:last-child {
        padding-bottom: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledImageContainer = styled('div')`
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const StyledPriceContainer = styled('div')`
    text-align: right;
    font-size: 14px;
    
    h3 {
        white-space: nowrap;
        display: inline;
    }
`;

const StyledMetaInfo = styled('div')`
    font-size: 14px;
    margin-bottom: 10px;
    
    span + span {
        margin-left: 20px;
    }
`;

const StyledInfoContainer = styled('div')`
    width: 100%;
`;

const StyledHeadlineContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    
    .cart-element__delete-element {
        height: 32px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 26px;
            height: 26px;
            fill: ${props => props.theme.colors.pink};
        }

        .loading-icon {
            width: 26px;
            height: 26px;
        }

        &:hover {
            cursor: pointer;
        }
    }
`;

const StyledPriceInfoContainer = styled('div')`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    select {
        width: 75px;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
`;

const StyledPriceInfoLeftSide = styled('div')`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 0;
    }
`;

const StyledPriceInfoRightSide = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const StyledQuantityContainer = styled('div')`
    margin-right: 20px;
`;

const StyledSinglePriceContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 18px;
`;

const RemoveButton = (props) => {
    const { productId } = props;
    const context = React.useContext(Context);
    const [loadingRemoval, setLoadingRemoval] = React.useState(false);

    const removeFromCartObject = (productId) => {
        const newCart = Object.assign({}, context.cart);
        delete newCart.items[productId];

        return newCart;
    }

    const removeFromCart = () => {
        const data = removeFromCartObject(productId);

        setLoadingRemoval(true);
        urlFetch('/api/carts/update', { successCallback: (response) => { setLoadingRemoval(false); context.updateCart(response); } }, null, 'POST', null, { email: context.userEmail, data }, context.token);
    }

    return (
        <div className='cart-element__delete-element' onClick={removeFromCart}>
            { loadingRemoval ? <LoadingIcon/> : <DeleteIcon/>}
        </div>
    )
}


function CartElement (props) {
    const { cartItem, product } = props;
    const context = React.useContext(Context);

    if (product.is_shipping_cost) {
        return null;
    }

    const productTotal = getFloatFromPriceString(product.price) * parseInt(cartItem.count);
    const productTotalString = printPriceInGermanFormatString(productTotal);

    return (
        <StyledCartElement className='cart-element'>
            <div className='cart-element__image-container'>
                <StyledImageContainer dangerouslySetInnerHTML={{ __html: product.image.list }}></StyledImageContainer>
            </div>

            <StyledInfoContainer>
                <StyledMetaInfo>
                    { product.external_id && <span>Art.Nr: {product.external_id}</span> }
                    { product.GTIN && <span>GTIN: {product.GTIN}</span> }
                </StyledMetaInfo>
                <StyledHeadlineContainer>
                    <h3>{product.title}</h3>
                    <RemoveButton productId={product.id}/>
                </StyledHeadlineContainer>

                {
                    cartItem &&
                    <StyledPriceInfoContainer>
                        <StyledPriceInfoLeftSide>
                            <StyledQuantityContainer>
                                <QuantitySelect value={cartItem.count} changeHandler={(e) => { updateCart(product.id, e.currentTarget.value, context) }}/>
                            </StyledQuantityContainer>
                            <StyledSinglePriceContainer>
                                <div>{product.price} €</div>
                                <div>per packaging unit</div>
                            </StyledSinglePriceContainer>
                        </StyledPriceInfoLeftSide>
                        <StyledPriceInfoRightSide>
                            <StyledPriceContainer>without VAT <h3>€ {productTotalString}</h3></StyledPriceContainer>
                        </StyledPriceInfoRightSide>
                    </StyledPriceInfoContainer>
                }
            </StyledInfoContainer>
        </StyledCartElement>
    )
}

export default CartElement;
