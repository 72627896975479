import React from 'react';
import urlFetch from "../../00_utilities/connectivity/urlFetch";
import {Context} from "../../00_utilities/context";
import Button from "../../02_atoms/Button";
import generateHCDPFile from "../../00_utilities/functions/generateHCDPFile";
import startFileDownload from "../../00_utilities/functions/startFileDownload";
import { ReactComponent as DownloadIcon } from '../../../icons/download.svg';
import styled from "styled-components";

const StyledHcdpDownload = styled('div')`
    .hcdp-download__title--mobile {
        display: inline;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            display: none;
        }
    }
    
    .hcdp-download__title--desktop {
        display: none;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            display: inline;
        }
    }
`;

function HCDPDownload () {
    const context = React.useContext(Context);
    const [loading, setLoading] = React.useState(false);

    const successCallback = (response) => {
        setLoading(false);

        const hcdpFileData = generateHCDPFile(response);
        startFileDownload(hcdpFileData, 'application/csv', response['file_name']);
    }

    const requestHCDPDataObject = () => {
        setLoading(true);

        urlFetch('/api/products/hcdp', { successCallback }, null, 'POST', null, { email: context.userEmail }, context.token);
    };

    return (
        <StyledHcdpDownload className="hcdp-download">
            <Button clickHandler={requestHCDPDataObject} loading={loading} inverted>
                <span className='hcdp-download__title--mobile'>Master data</span>
                <span className='hcdp-download__title--desktop'>Download master data</span>
                <DownloadIcon/>
            </Button>
        </StyledHcdpDownload>
    )
}

export default HCDPDownload;
