import DefaultPage from '../../05_templates/Default';
import React  from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { Context } from '../../00_utilities/context';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import HomeLink from '../../02_atoms/HomeLink';
import CartElement from '../../03_molecules/CartElement';
import NoProductsContainer from '../../03_molecules/NoProductsContainer';
import CheckoutElement from "../../03_molecules/CheckoutElement";

function Cart() {
    const [products, setProducts] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const context = React.useContext(Context);

    React.useEffect(() => {
        const itemsArray = 'items' in context.cart ? Object.values(context.cart.items) : null;

        if (itemsArray !== null && itemsArray.length > 0) {
            const ids = Object.values(context.cart.items).map((item) => {
                return item.product_id;
            });

            urlFetch('/api/products', { successCallback: (response) => { setLoading(false); setProducts(response.elements); } }, null, 'POST', {id: ids, limit: 100}, { email: context.userEmail }, context.token);
        }

        if (itemsArray === null || itemsArray.length === 0) {
            setProducts([]);
            setLoading(false);
        }
    }, [context.cart.items]); // eslint-disable-line

    const CartElements = () => {
        const elements = [];
        for (let i = 0; i < products.length; i += 1) {
            const product = products[i];
            const cartItem = context.cart.items[product.id];

            if (typeof cartItem === 'undefined') {
                continue;
            }

            elements.push(<CartElement setLoading={setLoading} cartItem={cartItem} product={product} key={`cart-element-${i}`}/>)
        }

        return (
            <div>{elements}</div>
        )
    }

    return (
        <DefaultPage loading={loading}>
            <ComponentContainer>
                <ContentContainer>
                    <HomeLink/>

                    <h1>QREO Cart</h1>
                    { products.length > 0 ? <CartElements/> : <NoProductsContainer/> }
                    { products.length > 0 && <CheckoutElement products={products}/> }
                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default Cart;
