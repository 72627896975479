import React from 'react';
import styled from 'styled-components';
import ArrowDownIcon from '../../../icons/empty-arrow-down-pink.svg';

const ITEM_TYPE_ALL = 'all';
const ITEM_TYPE_INSTRUMENT = 'instrument';
const ITEM_TYPE_DISPOSABLE = 'disposable';
const ITEM_TYPE_ACCESSORY = 'accessory';

const ITEM_TYPES_MAP = {
    [ITEM_TYPE_ALL]: 'All Items',
    [ITEM_TYPE_INSTRUMENT]: 'Instruments',
    [ITEM_TYPE_DISPOSABLE]: 'Disposables',
    [ITEM_TYPE_ACCESSORY]: 'Accessories',
}

const StyledItemsTypeFiltersContainer = styled('div')`

`;

const StyledItemsTypeFilterMobile = styled('div')`
    display: none;
    
    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        display: block;
    }
    
    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        display: none;
    }
`;

const StyledItemsTypeFilterMobileSelect = styled('select')`
    background: url(${ArrowDownIcon}) no-repeat calc(100% - 16px) calc(50% - 2px);
    background-size: 12px;
    background-color: ${props => props.theme.colors.white};
    appearance: none;
    border: 1px solid #DFDEDF;
    border-radius: 4px;
    padding: 4px 15px 4px 22px;
    width: 100%;
    min-width: 130px;
    color: ${props => props.theme.colors.pink};
    position: relative;

    &.active,
    &:active,
    &:focus {
        background-color: ${props => props.theme.colors.white};
        background-size: 12px;
        color: ${props => props.theme.colors.pink};
        outline: 0;
    }

    &.warning {
        border-color: red;
    }

    &:hover {
        cursor: pointer;
        border-color: #DFDEDF;
        color: ${props => props.theme.colors.pinkDark};
    }
`;

const StyledItemsTypeFilterDesktop = styled('div')`
    position: relative;
`;

const StyledItemsTypeFilterDesktopContainer = styled('div')`
    display: block;
    text-align: center;
    position: relative;

    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        display: none;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        display: block;
    }
`;

const StyledItemsTypeFilterDesktopHighlighter = styled('div')`
    display: none;
    content: '';
    pointer-events: none;
    color: transparent;
    height: 6px;
    width: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.colors.pink};
    transition: left 300ms, width 300ms;

    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        display: block;
    }
`;

const StyledItemsTypeFilterElement = styled('div')`
    padding: 12px;
    letter-spacing: 0;
    position: relative;
    display: inline-block;
    font-weight: ${props => props.theme.fontWeights.medium};
    color: ${props => props.theme.colors.lightGrey};

    &::before {
        content: '';
        width: 2px;
        height: calc(100% - 36px);
        position: absolute;
        top: 18px;
        left: 0;
        background-color: ${props => props.theme.colors.lightGrey};
    }

    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        padding: 12px 24px 12px 24px;

        &::before {
            content: none;
        }
    }
    
    &:first-child {
        padding-left: 0;
        
        &::before {
            content: none;
        }
    }
    
    &:last-child {
        padding-right: 0;
    }
    
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.black};
    }

    ${props => {
    if (props.active) {
        return `
                color: ${props.theme.colors.black};
            `;
    }
}};
`;

function ItemTypeListFilter (props) {
    const { updateHandler, currentItemType } = props;
    const highlighterRef = React.useRef(null);
    const desktopItemsRefs = React.useRef([]);

    React.useEffect(() => {
        updateHighlighter();
    }, [currentItemType]); // eslint-disable-line

    const getMobileFilter = () => {
        const domChildren = [];
        const mapKeys = Object.keys(ITEM_TYPES_MAP);

        for (let i = 0; i < mapKeys.length; i++) {
            const mapKey = mapKeys[i];
            const mapValue = ITEM_TYPES_MAP[mapKey];

            domChildren.push(<option key={`mobile-filter-item-${mapKey}`} value={mapKey}>{mapValue}</option>)
        }

        return <StyledItemsTypeFilterMobile>
            <StyledItemsTypeFilterMobileSelect value={currentItemType} onChange={(e) => { updateHandler(e.currentTarget.value); }}>{domChildren}</StyledItemsTypeFilterMobileSelect>
        </StyledItemsTypeFilterMobile>;
    }

    const updateHighlighter = () => {
        const mapKeys = Object.keys(ITEM_TYPES_MAP);
        const activatedElementIndex = mapKeys.indexOf(currentItemType);
        const activatedElement = desktopItemsRefs.current[activatedElementIndex];
        let left = activatedElement.offsetLeft;

        if (activatedElementIndex !== 0) {
            left += 24;
        }

        const activatedElementTextChild = activatedElement.querySelector('span');
        const textChildRect = activatedElementTextChild.getBoundingClientRect();
        const width = textChildRect.width;

        const highlighter = highlighterRef.current;
        highlighter.style.width = `${width}px`;
        highlighter.style.left = `${left}px`;
    }


    const getDesktopFilter = () => {
        const domChildren = [];
        const mapKeys = Object.keys(ITEM_TYPES_MAP);

        for (let i = 0; i < mapKeys.length; i++) {
            const mapKey = mapKeys[i];
            const mapValue = ITEM_TYPES_MAP[mapKey];

            const filterElement = <StyledItemsTypeFilterElement
                    ref={ref => { desktopItemsRefs.current[i] = ref; }}
                    key={`desktop-filter-item-${mapKey}`}
                    active={mapKey === currentItemType}
                    onClick={() => { updateHandler(mapKey); }}>
                        <span>{mapValue}</span>
                </StyledItemsTypeFilterElement>

            domChildren.push(filterElement)
        }

        return <StyledItemsTypeFilterDesktop>
            <StyledItemsTypeFilterDesktopHighlighter ref={highlighterRef}></StyledItemsTypeFilterDesktopHighlighter>
            <StyledItemsTypeFilterDesktopContainer>{domChildren}</StyledItemsTypeFilterDesktopContainer>
        </StyledItemsTypeFilterDesktop>
    }

    return (
        <StyledItemsTypeFiltersContainer>
            {getMobileFilter()}
            {getDesktopFilter()}
        </StyledItemsTypeFiltersContainer>
    )
}

export default ItemTypeListFilter;
