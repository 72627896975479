import React, {useEffect} from "react";
import styled from "styled-components";
import Header from '../../04_organisms/Header/index';
import Footer from '../../04_organisms/Footer/index';
import { default as DefaultTheme } from '../../07_themes/Default';
import PageLoading from '../../03_molecules/PageLoading';
import {Context} from "../../00_utilities/context";

const StyledMain = styled('main')`
    min-height: calc(100vh - 204px);
    position: relative;
    z-index: 2;
    flex-grow: 1;
`;

const StyledPageInnerWrapper = styled('div')`
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    margin-top: 50px;
`;

function DefaultPage(props) {
    const context = React.useContext(Context);
    const { setPath, isPublicPage } = context;
    const { children, popupActive, loading, backgroundImage = null } = props;

    useEffect(() => {
        setPath(window.location.pathname);
    });

    return (
        <DefaultTheme popupActive={popupActive}>
            <StyledPageInnerWrapper data-p={isPublicPage}>
                <Header popupActive={popupActive}/>
                { loading && <StyledMain popupActive={popupActive}><PageLoading/></StyledMain> }
                { !loading && <StyledMain popupActive={popupActive}>{children}</StyledMain> }
                { backgroundImage }
                <Footer />
            </StyledPageInnerWrapper>
        </DefaultTheme>
    );
}

export default DefaultPage;
