import React from 'react';
import styled from 'styled-components';
import urlFetch from "../../00_utilities/connectivity/urlFetch";
import {Context} from "../../00_utilities/context";
import Button from "../../02_atoms/Button";
import { ReactComponent as SubmittedIcon } from '../../../icons/save.svg';
import { ReactComponent as SendIcon } from '../../../icons/paper-plane.svg';

const StyledFeedbackForm = styled('div')`
    max-width: 840px;
    
    .button {
        padding: 18px;
    }
`;

const StyledFormHeader = styled('div')`
    margin-bottom: 30px;
    
    svg {
        width: 60px;
        height: 60px;
        fill: ${props => props.theme.colors.pink};
        margin-bottom: 10px;
    }
`;

const StyledIconOverlay = styled('span')`
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background: white;
    transition: width 800ms;
    
    &.activated {
        width: 0;
    }
`;

const StyledThankYou = styled('div')`
    display: flex;
    
    svg {
        width: 40px;
        height: 40px;
        margin-bottom: -5px;
        margin-left: 5px;
        
        path {
            fill: ${props => props.theme.colors.pink};
        }
    }
    
    h3 > span {
        position: relative;
        display: inline-block;
    }

    br {
        @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
            display: none;
        }
    }
`;

const StyledTextarea = styled('textarea')`
    box-sizing: border-box;
    appearance: none;
    border-radius: 4px;
    font-size: 16px;
    padding: 12px 20px;
    border: 1px solid ${props => props.theme.colors.randomBorderGrey};
    width: 100%;
    height: 160px;
    color: ${props => props.theme.colors.black};
    font-family: 'BrainlabNowW05', sans-serif;
    position: relative;
    resize: none;
    margin-bottom: 20px;
`;

const FORM_TARGET_LIST = 'list';

function FeedbackForm (props) {
    const [submitted, setSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const context = React.useContext(Context);
    const { target = FORM_TARGET_LIST} = props;
    const iconOverlayRef = React.useRef(null);

    React.useEffect(() => {
        if (submitted && iconOverlayRef.current) {
            requestAnimationFrame(() => {
                const iconOverlay = iconOverlayRef.current;
                iconOverlay.classList.add('activated');
            });
        }
    }, [submitted]); // eslint-disable-line

    const successCallback = (response) => {
        setLoading(false);
        setSubmitted(true);
    }

    const sendFeedback = () => {
        setLoading(true);

        const body = {
            email: context.userEmail,
            data: {
                message
            },
        }

        urlFetch('/api/feedback/write', { successCallback }, null, 'POST', null, body, context.token);
    };

    if (submitted) {
        return (
            <StyledThankYou>
                <h3>Thank you for your <br/>feedback! <span><StyledIconOverlay ref={iconOverlayRef}/><SubmittedIcon/></span></h3>
            </StyledThankYou>
        )
    }

    const getHeaderByTarget = () => {
        return (<StyledFormHeader>
            { target === FORM_TARGET_LIST && <SendIcon/> }
            <h3>We would like to improve QREO constantly for you. <br/>Please give us feedback!</h3>
            <p>Thanks for your support!</p>
        </StyledFormHeader>);
    }

    return (
        <StyledFeedbackForm>
            { getHeaderByTarget() }
            <StyledTextarea onChange={(e) => { setMessage(e.currentTarget.value); }} placeholder="Your message"/>
            <Button clickHandler={sendFeedback} loading={loading} disabled={message === ''}>Send feedback</Button>
        </StyledFeedbackForm>
    )
}

export default FeedbackForm;
