import React from 'react';
import styled from 'styled-components';
import ImageWrapper from '../../01_base/layout/ImageWrapper';
import QuantitySelect from '../../02_atoms/QuantitySelect';
import LightGallery from "lightgallery/react";
import {Context} from "../../00_utilities/context";
import AddToCartButton from "../AddToCartButton";
import { ReactComponent as MagnifierIcon } from '../../../icons/magnifier.svg';

const StyledProductCard = styled('div')`
`;

const StyledProductCardInnerWrapper = styled('div')`
    background-color: ${props => props.theme.colors.whiteSmoke};
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 32px);
    
    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledImageContainer = styled('div')`
    flex-shrink: 0;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: 20px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: auto;
    }
`;

const StyledImageInnerContainer = styled('div')`
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
    aspect-ratio: 4 / 2.85;

    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        width: 50%;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 200px;
        height: 130px;
    }
`;

const StyledProductTitle = styled('h4')`
    margin-bottom: 0;
`;

const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledUpperContentContainer = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const StyledUpperLeftContentContainer = styled('div')`
`;

const StyledTagsContainer = styled('div')`
    margin-bottom: 20px;
`;

const StyledTag = styled('span')`
    font-size: 14px;
    padding: 2px 6px;
    border-radius: 6px;
    background-color: ${props => props.theme.colors.white};

    + span {
        margin-left: 8px;
    }
`;

const StyledLowerContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        align-items: flex-end;
        flex-direction: column;
    }

    @media (min-width: ${props => props.theme.breakpoints.max.breakpoint}px) {
        flex-direction: row;
    }

    .quantity-select-container {
        max-width: 70px;
        margin-right: 10px;
        flex-shrink: 0;

        &::before {
            left: 14px;
            font-size: 12px;
        }

        select {
            width: 70px;
            padding: 16px 15px 0 14px;
        }
    }

    .button,
    a,
    .interactive-link {
        padding: 15px 15px 14px 15px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            padding: 15px 18px 14px 18px;
        }

        svg {
            width: 18px;
            height: 16px;
            margin-bottom: -2px;
            margin-left: 2px;
        }
    }
`;

const StyledProductPriceContainer = styled('div')`
    text-align: right;
    margin-bottom: 10px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
        margin-right: 10px;
        margin-bottom: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        margin-bottom: 10px;
        margin-right: 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.max.breakpoint}px) {
        margin-bottom: 0;
        margin-right: 10px;
    }
`;

const StyledProductPrice = styled('h4')`
    margin-bottom: 0;
`;

const StyledProductTaxInfo = styled('span')`
    font-size: 14px;
    letter-spacing: 0;
`;

const StyledInputsContainer = styled('div')`
    display: flex;
    align-items: flex-end;
`;

const StyledGalleryIconContainer = styled('div')`
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 8px;
    pointer-events: none;

    svg {
        width: 20px;
        height: 20px;
        fill: ${props => props.theme.colors.midGrey};
    }
`;

function ProductCard (props) {
    const { product, setAddedProduct } = props;
    const context = React.useContext(Context);
    const [count, setCount] = React.useState(1);
    const taxString = '(per packaging unit) without VAT';


    const updateQuantityCount = (e) => {
        let value = e.currentTarget.value;

        if (value !== '' && Number(value) < 1) {
            value = 1;
        }

        setCount(value);
    }

    const resetQuantityCount = (e) => {
        let value = e.currentTarget.value;

        if (value === '') {
            setCount(1);
        }
    }

    const updatePopupState = () => {
        context.setPopupActive(true);
        setAddedProduct(product);
    }

    return (
        <StyledProductCard className='product-card'>
            <StyledProductCardInnerWrapper className='product-card__inner-wrapper'>
                <StyledImageContainer>
                    <StyledImageInnerContainer>
                        <StyledGalleryIconContainer>
                            <MagnifierIcon/>
                        </StyledGalleryIconContainer>

                        <LightGallery counter={false} download={false} licenseKey={context.lightGalleryLicenseKey}>
                            <a href="/" data-src={ product.popup_image.src } data-srcset={ product.popup_image.src_set }>
                                <ImageWrapper dangerouslySetInnerHTML={{ __html: product.image.detail }}/>
                            </a>
                        </LightGallery>
                    </StyledImageInnerContainer>
                </StyledImageContainer>

                <StyledContentContainer>
                    <StyledUpperContentContainer>
                        <StyledUpperLeftContentContainer>
                            <StyledTagsContainer>
                                { product.external_id && <StyledTag>Art.Nr: {product.external_id}</StyledTag> }
                                { product.GTIN && <StyledTag>GTIN: {product.GTIN}</StyledTag> }
                            </StyledTagsContainer>
                            <StyledProductTitle>{product.title}</StyledProductTitle>
                        </StyledUpperLeftContentContainer>
                    </StyledUpperContentContainer>

                    <StyledLowerContentContainer>
                        <StyledProductPriceContainer>
                            <StyledProductPrice>€ {product.price}</StyledProductPrice>
                            <StyledProductTaxInfo>{taxString}</StyledProductTaxInfo>
                        </StyledProductPriceContainer>
                        <StyledInputsContainer>
                            <QuantitySelect value={count} blurHandler={resetQuantityCount} changeHandler={updateQuantityCount}/>
                            <AddToCartButton product={product} count={count} updatePopupState={updatePopupState}/>
                        </StyledInputsContainer>
                    </StyledLowerContentContainer>
                </StyledContentContainer>

            </StyledProductCardInnerWrapper>
        </StyledProductCard>
    )
}

export default ProductCard;
