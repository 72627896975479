import React from "react";
import styled, { css } from "styled-components";
import ArrowDownIcon from '../../../icons/empty-arrow-down.svg';

const StyledSelectContainer = styled('div')`
    position: relative;

    &:before {
        content: '${props => props.floatingTitle}';
        pointer-events: none;
        color: #B4B1B3;
        font-size: 13px;
        position: absolute;
        top: 6px;
        left: 15px;
        z-index: 1;
    }
`;

const inputBaseStyle = css`
    background: url(${ArrowDownIcon}) no-repeat calc(100% - 15px) calc(50% + 5px);
    background-size: 14px;
    background-color: ${props => props.theme.colors.white};
    appearance: none;
    border: 1px solid #DFDEDF;
    border-radius: 4px;
    height: 55px;
    padding: 16px 30px 0 14px;
    width: 100%;
    color: ${props => props.theme.colors.black};
    position: relative;

    &.active,
    &:active,
    &:focus {
        background-color: ${props => props.theme.colors.white};
        background-size: 14px;
        color: ${props => props.theme.colors.black};
        outline: 0;
    }

    &.warning {
        border-color: red;
    }

    &:hover {
        cursor: pointer;
        border-color: #DFDEDF;
    }
`;

const StyledSelect = styled('select')` 
    ${inputBaseStyle};
`;

const QuantitySelect = (props) => {
    const { name, value, changeHandler } = props;
    const options = [];

    for (let i = 1; i <= 30; i += 1) {
        const key = i;
        const text = i;

        options.push(
            <option key={`quantity-select-${key}`} value={key}>{text}</option>
        )
    }

    return (
        <StyledSelectContainer floatingTitle='Quantity' className='quantity-select-container'>
            <StyledSelect name={name} value={value} onChange={changeHandler}>
                { options }
            </StyledSelect>
        </StyledSelectContainer>
    )
}

export default QuantitySelect;
