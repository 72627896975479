module.exports = () => {
    const script = document.createElement('script');
    script.id = 'usercentrics-cmp';
    script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
    script.setAttribute('data-settings-id', 'vQVV4bAwFsmUnp');
    script.async = true;

    if (process.env.NODE_ENV === 'development' || window.location.origin.includes('schalk-development')) {
        script.setAttribute('data-version', 'preview');
    }

    document.head.appendChild(script);
};
