import React from 'react';
import styled from 'styled-components';
import ImageWrapper from '../../01_base/layout/ImageWrapper';
import QuantitySelect from '../../02_atoms/QuantitySelect';
import LightGallery from 'lightgallery/react';
import {Context} from "../../00_utilities/context";
import AddToCartButton from "../AddToCartButton";
import { ReactComponent as MagnifierIcon } from '../../../icons/magnifier.svg';

const StyledProductListItem = styled('div')`
`;

const StyledProductListItemInnerWrapper = styled('div')`
    background-color: ${props => props.theme.colors.whiteSmoke};
    padding: 24px 30px 30px 30px;
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledImageContainer = styled('div')`
    width: 100%;
    max-width: 400px;
    border-radius: 24px;
    flex-shrink: 0;
    margin-right: 40px;
    aspect-ratio: 4 / 2.85;
    margin-bottom: 20px;

    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        width: 50%;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: 30%;
        margin-bottom: 0;
    }
    
    img {
        object-fit: contain;
        background-color: ${props => props.theme.colors.white};
    }
`;

const StyledImageInnerContainer = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    flex-shrink: 0;
`;

const StyledProductTitle = styled('h3')`
    margin-bottom: 10px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        margin-bottom: 20px;
    }
`;

const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledUpperContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledUpperLeftContentContainer = styled('div')`
    margin-right: 40px;
    width: 100%;
    max-width: 620px;
`;

const StyledTagsContainer = styled('div')`
    margin-bottom: 20px;
`;

const StyledTag = styled('span')`
    font-size: 14px;
    padding: 2px 6px;
    border-radius: 6px;
    background-color: ${props => props.theme.colors.white};
    
    + span {
        margin-left: 8px;
    }
`;

const StyledUpperRightContentContainer = styled('div')`
    padding-top: 40px;
    text-align: right;
`;

const StyledLowerContentContainer = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: auto;
    
    .quantity-select-container {
        max-width: 70px;
        margin-right: 10px;

        &::before {
            left: 14px;
            font-size: 12px;
        }
        
        select {
            width: 70px;
            padding: 16px 15px 0 14px;
        }
    }

    .button,
    a,
    .interactive-link {
        padding: 15px 15px 14px 15px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            padding: 15px 18px 14px 18px;
        }

        svg {
            width: 18px;
            height: 16px;
            margin-bottom: -2px;
            margin-left: 2px;
        }
    }
`;

const StyledProductPrice = styled('h3')`
    margin-bottom: 0;
`;

const StyledProductTaxInfo = styled('span')`
    font-size: 14px;
    letter-spacing: 0;
`;

const StyledGalleryIconContainer = styled('div')`
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 12px;
    pointer-events: none;
    
    svg {
        width: 32px;
        height: 32px;
        fill: ${props => props.theme.colors.midGrey};
    }
`;

function ProductListItem (props) {
    const { product, setAddedProduct } = props;
    const context = React.useContext(Context);
    const [count, setCount] = React.useState(1);
    const taxString = '(per packaging unit) without VAT';

    const updateQuantityCount = (e) => {
        let value = e.currentTarget.value;

        if (value !== '' && Number(value) < 1) {
            value = 1;
        }

        setCount(value);
    }

    const resetQuantityCount = (e) => {
        let value = e.currentTarget.value;

        if (value === '') {
            setCount(1);
        }
    }

    const updatePopupState = () => {
        context.setPopupActive(true);
        setAddedProduct(product);
    }

    return (
        <StyledProductListItem className='product-list-item'>
            <StyledProductListItemInnerWrapper className='product-list-item__inner-wrapper'>
                <StyledImageContainer>
                    <StyledImageInnerContainer>
                        <StyledGalleryIconContainer>
                            <MagnifierIcon/>
                        </StyledGalleryIconContainer>

                        <LightGallery counter={false} download={false} licenseKey={context.lightGalleryLicenseKey}>
                            <a href="/" data-src={ product.popup_image.src } data-srcset={ product.popup_image.src_set }>
                                <ImageWrapper dangerouslySetInnerHTML={{ __html: product.image.detail }}/>
                            </a>
                        </LightGallery>
                    </StyledImageInnerContainer>
                </StyledImageContainer>

                <StyledContentContainer>
                    <StyledUpperContentContainer>
                        <StyledUpperLeftContentContainer>
                            <StyledTagsContainer>
                                { product.external_id && <StyledTag>Art.Nr: {product.external_id}</StyledTag> }
                                { product.GTIN && <StyledTag>GTIN: {product.GTIN}</StyledTag> }
                            </StyledTagsContainer>
                            <StyledProductTitle>{product.title}</StyledProductTitle>
                            <div>{product.description}</div>
                        </StyledUpperLeftContentContainer>

                        <StyledUpperRightContentContainer>
                            <StyledProductPrice>€ {product.price}</StyledProductPrice>
                            <StyledProductTaxInfo>{taxString}</StyledProductTaxInfo>
                        </StyledUpperRightContentContainer>
                    </StyledUpperContentContainer>

                    <StyledLowerContentContainer>
                        <QuantitySelect value={count} blurHandler={resetQuantityCount} changeHandler={updateQuantityCount} asInput/>
                        <AddToCartButton product={product} count={count} updatePopupState={updatePopupState}/>
                    </StyledLowerContentContainer>
                </StyledContentContainer>

            </StyledProductListItemInnerWrapper>
        </StyledProductListItem>
    )
}

export default ProductListItem;
