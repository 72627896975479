import React from "react";
import styled from "styled-components";
import urlFetch from "../../00_utilities/connectivity/urlFetch";

const StyledFooter = styled('div')`
    z-index: 1;
`;

const StyledFooterContainer = styled('div')`
    z-index: 1;
    background-color: #DFDEDF;
    height: 610px;

    @media (min-width: 1024px) {
        height: 591px;
    }
`;

const footerApiUrl = 'https://footer.brainlab.com/wp-json/footer-menu/v1/footer/40?domain=qreo';

function Footer() {
    const [footerJson, setFooterJson] = React.useState(null);
    const [footerJsonLoaded, setFooterJsonLoaded] = React.useState(false);
    const translations = footerJson !== null && 'translations' in footerJson ? footerJson.translations : null;
    const language = 'en';
    const footerMarkup = translations !== null && language in translations ? translations[language] : null;

    React.useEffect(() => {
        urlFetch(footerApiUrl, {
            serverErrorCallback: (error) => { console.log('server error', error); },
            clientErrorCallback: (error) => { console.log('client error', error); },
            successCallback: (response) => {
                createFooterStyleTagByApiResponse(response);

                setFooterJsonLoaded(true);
                setFooterJson(response);
            }
        });

    }, [footerJsonLoaded]); // eslint-disable-line

    const createFooterStyleTagByApiResponse = (response) => {
        const footerCss = response !== null && 'css' in response ? response.css : null;
        const styleTag = document.createElement('style');

        styleTag.appendChild(document.createTextNode(footerCss));
        document.head.appendChild(styleTag);
    }

    return (
        <StyledFooterContainer>
            <StyledFooter dangerouslySetInnerHTML={{ __html: footerMarkup }}></StyledFooter>
        </StyledFooterContainer>
    );
}

export default Footer;
