import styled from "styled-components";

const StyledFormContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    > .form-input-container,
    > .form-input-column {
        padding: 0 15px;
        box-sizing: border-box;
    }

    > .form-input-container__pull-up {
        color: ${props => props.theme.colors.randomGrey};
        margin-top: -15px;
    }
`;


const FormContainer = (props) => {
    return (
        <StyledFormContainer className='form-container'>{props.children}</StyledFormContainer>
    )
};

export default FormContainer;
