import React from 'react';
import styled from 'styled-components';
import { Context } from '../../00_utilities/context';
import Button from "../../02_atoms/Button";
import {Link} from "react-router-dom";
import getFloatFromPriceString from '../../00_utilities/functions/getFloatFromPriceString';
import printPriceInGermanFormatString from '../../00_utilities/functions/printPriceInGermanFormatString';

const StyledCheckoutElement = styled('div')`
    margin-top: 20px;

    .checkout-element__total-sum-container {
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        text-align: right;
        background-color: ${props => props.theme.colors.whiteSmoke};
        margin-bottom: 35px;
    }

    .checkout-element__buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .button {
            text-align: center;
            letter-spacing: 0;
            padding: 0;
            margin-bottom: 20px;
            
            a {
                display: block;
                padding: 16px 20px;
                margin: 0;
            }
        }

        @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
            flex-direction: row;

            .button {
                text-align: left;
            }
        }
    }
    
    hr {
        background-color: white;
        border-top: 2px solid ${props => props.theme.colors.randomGrey};
        border-right: 0;
        border-left: 0;
        border-bottom: 0;
        padding-bottom: 20px;
        margin: 0 -20px;
    }
`;

const StyledPriceContainer = styled('div')`
    text-align: right;
    font-size: 18px;

    h3, h4 {
        white-space: nowrap;
        display: inline;
    }
`;

const StyledShippingElement = styled('div')`
    text-align: left;
    background-color: ${props => props.theme.colors.white};
    margin: 0 -20px;
    padding: 20px 20px 40px 20px;

    .shipping-element__meta-info {
        font-size: 14px;
        margin-bottom: 10px;

        span + span {
            margin-left: 20px;
        }
    }

    .shipping-element__price-info {
        font-size: 14px;
        text-align: right;
        
        h3 {
            white-space: nowrap;
            display: inline;
        }
    }
`;

function CheckoutElement (props) {
    const context = React.useContext(Context);
    const { products, isCheckout = false } = props;
    const hasAccount = !!context.customer && context.customer.account;
    const taxPercentage = hasAccount ? parseFloat(context.customer.account.tax) : null;
    const discountPercentage = hasAccount ? parseFloat(context.customer.account.discount) : null;
    let shippingProduct = null;
    let subtotal = 0;
    let shipping = 0;

    if (typeof context.cart.items === 'undefined') {
        return <div></div>;
    }

    for (let i = 0; i < products.length; i += 1) {
        const product = products[i];
        const cartItem = context.cart.items[product.id];

        if (typeof cartItem === 'undefined') {
            continue;
        }

        if (product['is_shipping_cost']) {
            shipping = getFloatFromPriceString(product.price);
            shippingProduct = product;

            continue;
        }

        subtotal += getFloatFromPriceString(product.price) * parseInt(cartItem.count);
    }

    const discount = subtotal * (discountPercentage/100);
    const subtotalAfterDiscount = subtotal - discount;

    const subtotalAfterDiscountAndShipping = subtotalAfterDiscount + shipping;

    const tax = subtotalAfterDiscountAndShipping * (taxPercentage/100);
    const total = subtotalAfterDiscountAndShipping + tax;

    const getShippingElement = () => {
        if (!shippingProduct) {
            return null;
        }

        return (
            <StyledShippingElement>
                <div className='shipping-element__meta-info'>
                    <span>Art.Nr: {shippingProduct.external_id}</span>
                </div>
                <div>
                    <h3>{shippingProduct.title}</h3>
                    <p>{shippingProduct.description}</p>
                </div>

                <div className='shipping-element__price-info'>without VAT <h3>€ {shippingProduct.price}</h3></div>
            </StyledShippingElement>
        )
    }

    return (
        <StyledCheckoutElement>
            { isCheckout &&
                <div className='checkout-element__total-sum-container'>
                    { hasAccount &&
                        <>
                            <StyledPriceContainer><h4>Subtotal products € {printPriceInGermanFormatString(subtotal)}</h4></StyledPriceContainer>
                            <StyledPriceContainer>-{discountPercentage}% Discount (contractually agreed) € -{printPriceInGermanFormatString(discount)}</StyledPriceContainer>
                            <br/>
                            <div>{getShippingElement()}</div>
                            <hr/>
                            <br/>
                            <StyledPriceContainer><h4>Net total € {printPriceInGermanFormatString(subtotalAfterDiscountAndShipping)}</h4></StyledPriceContainer>
                            <br/>
                            <StyledPriceContainer>{taxPercentage}% VAT € {printPriceInGermanFormatString(tax)}</StyledPriceContainer>
                            <StyledPriceContainer><h3>Total € {printPriceInGermanFormatString(total)}</h3></StyledPriceContainer>
                        </>
                    }
                    { !hasAccount && shipping > 0 &&
                        <>
                            <StyledPriceContainer><h4>Subtotal products € {printPriceInGermanFormatString(subtotal)}</h4></StyledPriceContainer>
                            <br/>
                            <div>{getShippingElement()}</div>
                            <hr/>
                            <br/>
                            <StyledPriceContainer><h3>Total € {printPriceInGermanFormatString(total)}</h3></StyledPriceContainer>
                        </>
                    }
                    { !hasAccount && shipping === 0 &&
                        <>
                            <StyledPriceContainer><h3>Total € {printPriceInGermanFormatString(total)}</h3></StyledPriceContainer>
                        </>
                    }
                </div>
            }

            { !isCheckout &&
                <>
                    <div className='checkout-element__total-sum-container'>
                        <StyledPriceContainer><h3>Subtotal products € {printPriceInGermanFormatString(subtotal)}</h3></StyledPriceContainer>
                    </div>

                    <div className='checkout-element__buttons-container'>
                        <Button inverted>
                            <Link to='/'>Continue product selection</Link>
                        </Button>
                        <Button>
                            <Link to='/checkout'>Proceed to checkout</Link>
                        </Button>
                    </div>
                </>
            }
        </StyledCheckoutElement>
    )
}

export default CheckoutElement;
