import DefaultPage from '../../05_templates/Default';
import React from 'react';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { Redirect } from 'react-router-dom';
import { Context } from '../../00_utilities/context';
import styled from 'styled-components';
import ComponentContainer from '../../01_base/layout/ComponentContainer';
import ContentContainer from '../../01_base/layout/ContentContainer';
import HomeLink from '../../02_atoms/HomeLink';
import CartElement from '../../03_molecules/CartElement';
import NoProductsContainer from '../../03_molecules/NoProductsContainer';
import CheckoutElement from "../../03_molecules/CheckoutElement";
import HeadlessContentElements from "../../01_base/HeadlessContentElements";
import CheckoutSubmissionForm from "../../04_organisms/CheckoutSubmissionForm";

const StyledCheckoutWrapper = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        align-items: flex-start;
        flex-direction: row;
    }
`;

const StyledCheckoutContent = styled('div')`
    width: 100%;

    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        margin-right: 30px;
    }
    
    > hr {
        border-top: 2px solid ${props => props.theme.colors.randomGrey};
        border-right: 0;
        border-left: 0;
        border-bottom: 0;
    }
`;

const StyledCheckoutContentHeader = styled('div')`
    width: 100%;
    border-bottom: 2px solid ${props => props.theme.colors.randomBorderGrey};
    position: relative;
    
    &::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: white;
        border-left: 2px solid ${props => props.theme.colors.randomBorderGrey};
        border-top: 2px solid ${props => props.theme.colors.randomBorderGrey};
        border-bottom: none;
        transform: rotate(-135deg);
        left: 70px;
        bottom: -7px
    }
`;

const StyledHeadlessCheckoutInformationWrapper = styled('div')`
    p:first-child {
        margin-top: 0;
    }
`;

function Checkout() {
    const [products, setProducts] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [checkoutSuccess, setCheckoutSuccess] = React.useState(false);
    const [orderNumber, setOrderNumber] = React.useState('');
    const [headlessContent, setHeadlessContent] = React.useState(null);
    const context = React.useContext(Context);

    React.useEffect(() => {
        const itemsArray = 'items' in context.cart ? Object.values(context.cart.items) : null;

        if (itemsArray !== null && itemsArray.length > 0) {
            const ids = Object.values(context.cart.items).map((item) => {
                return item.product_id;
            });

            urlFetch('/api/products', { successCallback: (response) => { setLoading(false); setProducts(response.elements); } }, null, 'POST', {id: ids, showShippingCost: true, limit: 100}, { email: context.userEmail }, context.token);
        }

        if (itemsArray === null || itemsArray.length === 0) {
            setProducts([]);
            setLoading(false);
        }

        if (headlessContent === null) {
            urlFetch(`/api/headless-content/checkout`, { successCallback: headlessContentSuccessCallback }, null, 'POST', null, { email: context.userEmail });
        }
    }, [context.cart.items]); // eslint-disable-line

    const headlessContentSuccessCallback = (response) => {
        setHeadlessContent(response);
    }

    const CartElements = () => {
        const elements = [];

        if (typeof context.cart.items === 'undefined') {
            return [];
        }

        for (let i = 0; i < products.length; i += 1) {
            const product = products[i];
            const cartItem = context.cart.items[product.id];

            if (typeof cartItem === 'undefined') {
                continue;
            }

            elements.push(<CartElement cartItem={cartItem} product={product} key={`cart-element-${i}`}/>)
        }

        return (
            <div>{elements}</div>
        )
    }
    const buyableProducts = products.length > 0 ? products.filter((product) => { return !product.is_shipping_cost; }) : [];

    return (
        <DefaultPage loading={loading}>
            {
                checkoutSuccess && orderNumber !== '' &&
                <Redirect to={{pathname: '/success', state: { orderNumber }} }/>
            }

            <ComponentContainer>
                <ContentContainer>
                    <HomeLink title="Back to product selection"/>
                    <h1>Checkout</h1>

                    <StyledCheckoutWrapper>
                        <StyledCheckoutContent>
                            <StyledCheckoutContentHeader>
                                <StyledHeadlessCheckoutInformationWrapper>
                                    <HeadlessContentElements headlessContent={headlessContent}/>
                                </StyledHeadlessCheckoutInformationWrapper>
                                <h2>Your QREO Cart</h2>
                            </StyledCheckoutContentHeader>
                            { buyableProducts.length > 0 ? <CartElements/> : <NoProductsContainer/> }
                            { buyableProducts.length > 0 && <hr/> }
                            { buyableProducts.length > 0 && <CheckoutElement products={products} isCheckout={true}/> }
                        </StyledCheckoutContent>

                        {
                            buyableProducts.length > 0 &&
                            <CheckoutSubmissionForm setCheckoutSuccess={setCheckoutSuccess} setOrderNumber={setOrderNumber}/>
                        }

                    </StyledCheckoutWrapper>
                </ContentContainer>
            </ComponentContainer>
        </DefaultPage>
    );
}

export default Checkout;
