import React from 'react';
import styled from 'styled-components';

const StyledProductSearch = styled('div')`
    input {
        box-sizing: border-box;
        appearance: none;
        border-radius: 5px;
        font-size: 16px;
        padding: 16px 20px 15px 20px;
        border: 1px solid ${props => props.theme.colors.yetAnotherRandomBorderGrey};
        width: 100%;
        color: ${props => props.theme.colors.black};
        font-family: 'BrainlabNowW05', sans-serif;
        position: relative;
        
        &:hover {
            border: 1px solid ${props => props.theme.colors.anotherRandomBorderGrey};
            outline: none;
        }
        
        &:focus,
        &:active {
            border: 1px solid ${props => props.theme.colors.inputFocusBorderColor};
            outline: none;
        }
    }
`;

function ProductSearch (props) {
    const { setSearch } = props;
    const delay = 200;
    let timeout = null;

    const changeHandler = (e) => {
        const target = e.currentTarget;
        const searchValue = target.value;

        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            setSearch(searchValue);
        }, delay);
    }

    return (
        <StyledProductSearch>
            <input type='text' placeholder='Search for products' autoComplete='off' onChange={changeHandler}/>
        </StyledProductSearch>
    )
}

export default ProductSearch;
