import React from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Popup from '../../02_atoms/Popup';
import Button from '../../02_atoms/Button';
import { ReactComponent as CartIcon } from '../../../icons/cart.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../icons/arrow-left.svg';
import {Context} from "../../00_utilities/context";

const StyledHeadlineContainer = styled('div')`
    width: 100%;
    margin-bottom: 10px;
`;

const StyledBodyContainer = styled('div')`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledButtonsContainer = styled('div')`
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .button {
        padding: 15px 15px 14px 15px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            padding: 15px 18px 14px 18px;
        }
    }
    
    .button:first-child {
        svg {
            margin-bottom: -2px;
            padding: 0;
            width: 16px;
            height: 16px;
        }
    }
    
    .button:nth-child(2) {
        svg {
            margin-bottom: -2px;
            margin-left: 2px;
            width: 18px;
            height: 16px;
        }
    }
`;

const AddedProductPopup = (props) => {
    const { product, setAddedProduct } = props;
    const context = React.useContext(Context);

    return (
        <Popup setShowPopup={setAddedProduct}>
            <StyledHeadlineContainer>Added to cart:</StyledHeadlineContainer>

            <StyledBodyContainer>
                <StyledContentContainer>
                    <h3>{product.title}</h3>
                    <StyledButtonsContainer>
                        <Button clickHandler={() => { context.setPopupActive(false); setAddedProduct(null); }} inverted>
                            <ArrowLeftIcon/> Continue product selection
                        </Button>

                        <Button clickHandler={() => { context.setPopupActive(false); setAddedProduct(null); }}>
                            <Link to='/cart'>View cart <CartIcon/></Link>
                        </Button>
                    </StyledButtonsContainer>
                </StyledContentContainer>
            </StyledBodyContainer>
        </Popup>
    )
}

export default AddedProductPopup;
