import React, {Suspense} from "react";
import ComponentContainer from "../layout/ComponentContainer";
import ContentContainer from "../layout/ContentContainer";

const HeadlessContentElements = (props) => {
    const { headlessContent, withComponentContainer, additionalProps } = props;

    if (headlessContent === null || !headlessContent.hasOwnProperty('elements') ) {
        return null;
    }

    const elements = headlessContent.elements.map((configuration, key) => {
        const Component = React.lazy(() => import(`../../03_molecules/${configuration.name}`));

        if (withComponentContainer) {
            return <Suspense key={`headless-content-element-${key}`} fallback={<div>Loading...</div>}>
                <ComponentContainer>
                    <ContentContainer>
                        <Component configuration={configuration} additionalProps={additionalProps}/>
                    </ContentContainer>
                </ComponentContainer>
            </Suspense>
        }

        return <Suspense key={`headless-content-element-${key}`} fallback={<div>Loading...</div>}>
            <Component configuration={configuration} additionalProps={additionalProps}/>
        </Suspense>
    });

    return <>{elements}</>;
}

export default HeadlessContentElements;
