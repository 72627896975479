import React from "react";
import { Context } from '../../00_utilities/context';
import Button from '../../02_atoms/Button';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import { ReactComponent as PencilIcon} from '../../../icons/edit.svg';
import { ReactComponent as SaveIcon} from '../../../icons/save.svg';
import FormInput from '../../02_atoms/FormInput';
import FormContainer from '../../01_base/layout/FormContainer';


const CustomerInformation = () => {
    const [loading, setLoading] = React.useState(false);
    const [customerInState, setCustomerInState] = React.useState(null);
    const [isEditingProfile, setIsEditingProfile] = React.useState(false);
    const context = React.useContext(Context);
    const { customer } = context;

    React.useEffect(() => {
        setCustomerInState(customer);
    }, [customer]);

    const fieldChangeFlags = () => {
        const changes = {};
        const customerKeys = Object.keys(customer);

        for (let i = 0; i < customerKeys.length; i += 1) {
            const key = customerKeys[i];

            changes[key] = customer[key] !== customerInState[key];
        }

        return changes;
    }

    const fieldsHaveChanged = () => {
        const flags = fieldChangeFlags();

        return Object.values(flags).includes(true);
    }

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        updateCustomer(name, value);
    }

    const updateCustomer = (key, value) => {
        const newCustomerInstate = Object.assign({}, customerInState);
        newCustomerInstate[key] = value;
        setCustomerInState(newCustomerInstate);
    }

    const editProfile = () => {
        setIsEditingProfile(true);
    };

    const saveProfile = () => {
        if (!fieldsHaveChanged()) {
            setIsEditingProfile(false);

            return;
        }

        setLoading(true);

        const body = {
            email: context.userEmail,
            data: customerInState,
        }

        urlFetch('/api/customers/update', { successCallback: (response) => { setLoading(false); setIsEditingProfile(false); context.updateCustomer(response); } }, null, 'POST', null, body, context.token);
    };

    const profileEditingClickHandler = isEditingProfile ? saveProfile : editProfile;
    const buttonText = isEditingProfile ? 'Save' : 'Edit name';

    let editButton = null;
    const icon = isEditingProfile ? <SaveIcon/> : <PencilIcon/>;

    if (customerInState !== null && Object.keys(customerInState).length > 0) {
        editButton = <Button clickHandler={profileEditingClickHandler} loading={loading}>{buttonText} {icon}</Button>;
    }

    const getValueByKey = (key) => {
        return (customerInState !== null && key in customerInState && customerInState[key] !== null) ? customerInState[key] : '';
    }

    return (
        <div>
            <FormContainer>
                <FormInput name={'firstName'} value={getValueByKey('firstName')} label='First name'  changeHandler={changeHandler} isEditing={isEditingProfile} componentWidth='50%'/>
                <FormInput name={'lastName'} value={getValueByKey('lastName')} label='Last name' changeHandler={changeHandler} isEditing={isEditingProfile} componentWidth='50%'/>
            </FormContainer>
            <br/>

            { editButton }
        </div>
    )
}

export default CustomerInformation;
