import React, { createContext } from "react";
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import getCookie from '../functions/getCookie';
import setCookie from '../functions/setCookie';
import resetCookie from '../functions/resetCookie';

const Context = createContext();
const startPagePath = '/start';
const lightGalleryLicenseKey = 'e850f1e5-d719-43a4-bf1e-39635f462e65';

const CustomContextProvider = (props) => {
    const [customer, setCustomer] = React.useState({});
    const [cart, setCart] = React.useState({});
    const [token, setToken] = React.useState(null);
    const [userEmail, setUserEmail] = React.useState(null);
    const [customerIsComplete] = React.useState(true);
    const [loginUrls, setLoginUrls] = React.useState(null);
    const [loginUrlsLoaded, setLoginUrlsLoaded] = React.useState(false);
    const [logoutUrl, setLogoutUrl] = React.useState(null);
    const [popupActive, setPopupActive] = React.useState(false);
    const [isPublicPage, setIsPublicPage] = React.useState(false);
    const [path, setPath] = React.useState(null);
    const isStartPage = window.location.pathname === startPagePath;

    React.useEffect(() => {
        urlFetch('/api/is-public-page', {
            successCallback: publicCheckSuccessCallback,
            clientErrorCallback: publicCheckErrorCallback,
            serverErrorCallback: publicCheckErrorCallback
        }, null, 'POST', null, { path: window.location.pathname });
    }, [token, userEmail, loginUrlsLoaded, path]); // eslint-disable-line


    /**
     * Public / Non-public handling
     */
    const publicCheckSuccessCallback = (response) => {
        const isPublic = 'is_public' in response ? response['is_public'] : false;
        setIsPublicPage(isPublic);

        if (isStartPage) {
            urlFetch('/api/login-urls', { successCallback: loginUrlsCallback }, null, 'POST', null, { target_url: window.location.origin });

            return;
        }

        if (!isPublic) {
            tokenCalls();
        }
    }

    const publicCheckErrorCallback = () => {
        setPath(window.location.pathname);
        setIsPublicPage(false);

        tokenCalls();
    }

    /**
     * Token calls if not public
     */
    const tokenCalls = () => {
        if (token === null || userEmail === null) {
            checkToken();
        }

        if (token !== null && userEmail !== null) {
            loadCart();
            urlFetch('/api/customers', { successCallback: updateCustomer }, null, 'POST', null, { email: userEmail }, token);
        }
    }

    /**
     * Cart handling
     */
    const loadCart = (additionalCallback = null) => {
        urlFetch('/api/carts', {
            successCallback: (cartResponse) => {
                setCart(cartResponse);
                if (typeof additionalCallback === 'function') {
                    additionalCallback();
                }
            }
        }, null, 'POST', null, {email: userEmail}, token);
    }

    /**
     * Customer handling
     */
    const updateCustomer = (customer) => {
        setCustomer(customer);
    }

    /**
     * Start page handling
     */

    const loginUrlsCallback = (response) => {
        setLoginUrlsLoaded(true);
        setLoginUrls(response);
    }

    /**
     * SSO handling
     */

    const tokenCheckCallback = (response) => {
        if (typeof response.token === 'undefined' || response.token === null) {
            redirectToStartPage();

            return;
        }

        setUserEmail(response.email);
        setToken(response.token);
        setLogoutUrl(response.logout_url);
    }

    const getValueFromUrlOrCookie = (key) => {
        const url = new URL(window.location.href);
        let value = null;

        if (getCookie(key) !== '') {
            value = getCookie(key);
        }

        if (value === null && url.searchParams.get(key)) {
            value = url.searchParams.get(key);
            setCookie(key, value, 1/24);
        }

        url.searchParams.delete(key);
        window.history.replaceState({}, '', url.toString());

        return value;
    }

    const checkToken = () => {
        const token = getValueFromUrlOrCookie('token');
        const ssoServiceKey = getValueFromUrlOrCookie('sso_service_key');

        urlFetch('/api/check-token', {
            successCallback: tokenCheckCallback,
        }, null, 'POST', null, { token, sso_service_key: ssoServiceKey, target_url: window.location.href, logout_redirect_url: `${window.location.origin}${startPagePath}` });
    }

    const redirectToStartPage = () => {
        window.location.href = startPagePath;
    }

    const logout = () => {
        resetCookie('token');
        setToken(null);
        setCustomer(null);
        setUserEmail(null);

        if (logoutUrl !== null) {
            window.location.href = logoutUrl;
        }
    }

    /**
     * Children + rendering
     */

    let children = null;

    if (token || isPublicPage) {
        children = props.children;
    }

    return (
        <Context.Provider value={{token, customer, cart, userEmail, customerIsComplete, updateCustomer, updateCart: setCart, logout, popupActive, setPopupActive, startPagePath, loginUrls, lightGalleryLicenseKey, loadCart, setPath, isPublicPage}}>
            {children}
        </Context.Provider>
    )
}

export { CustomContextProvider, Context };
export default Context.Consumer;
