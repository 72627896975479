import React from "react";
import styled from "styled-components";
import ArrowDownIcon from '../../../icons/empty-arrow-down.svg';
import CountrySelect from '../CountrySelect';

const StyledInputContainer = styled('div')`
    width: 100%;
    margin-bottom: ${props => props.isEditing ? '20px' : '0'};
    position: relative;
    
    label {
        position: absolute;
        color: ${props => props.theme.colors.randomGrey};
        font-size: 16px;
        height: 20px;
        width: calc(100% - 20px);
        background-color: ${props => props.theme.colors.white};
        top: 1px;
        left: 0;
        bottom: 0;
        pointer-events: none;

        ${props => {
            if (!props.isEditing) {
                return '';
            }

            return `
                left: 15px;
            `;
        }}
    }
    
    input,
    select,
    textarea {
        box-sizing: border-box;
        appearance: none;
        border-radius: 4px;
        font-size: 16px;
        padding: 22px 20px 8px 0;
        border: 1px solid transparent;
        width: 100%;
        color: ${props => props.theme.colors.black};
        font-family: 'BrainlabNowW05', sans-serif;
        position: relative;

        ${props => {
            if (!props.isEditing) {
                return '';
            }

            return `
                padding: 22px 20px 8px 15px;
                border: 1px solid #DFDEDF;
            `;
        }}

        &:empty + label {
            padding-top: 16px;
        }

        &:valid + label,
        &:disabled + label,
        &:placeholder-shown + label {
            padding-top: 6px;
            font-size: 13px;
        }

        &:focus + label {
            top: 2px;
            padding-top: 5px;
            font-size: 13px;
        }
        
        &:disabled {
            background-color: ${props => props.theme.colors.white};
            opacity: 1;
        }
    }
    
    textarea {
        resize: none;
        height: 130px;
    }
    
    select {
        ${props => {
            if (props.isEditing) {
                return `
                        background: url(${ArrowDownIcon}) no-repeat calc(100% - 15px) calc(50%);
                        background-size: 14px;
                        background-color: ${props => props.theme.colors.white};
                `;
            }
        }}
    }

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        width: ${props => props.componentWidth ? props.componentWidth : '50%'};
    }
`;

const StyledInputInnerWrapper = styled('div')`
    position: relative;
`;

const FormInput = (props) => {
    const { name, value, label, changeHandler, required = true, isCountrySelect, isTextArea, isEditing, componentWidth, showIsRequired = false} = props;
    const limit = 500;

    let input = (
        <input type='text' key={`text-input-${name}`} name={name} defaultValue={value} onKeyUp={changeHandler} required={required} disabled={!isEditing}/>
    );

    if (isTextArea) {
        input = (
            <textarea key={`select-${name}`} name={name} defaultValue={value} onKeyUp={changeHandler} required disabled={!isEditing} placeholder='-' maxLength={limit}/>
        )
    }

    if (isCountrySelect) {
        input = (
            <CountrySelect key={`select-${name}`} name={name} value={value} changeHandler={changeHandler} isEditing={isEditing}/>
        )
    }

    return (
        <StyledInputContainer className={'form-input-container'} isEditing={isEditing} componentWidth={componentWidth}>
            <StyledInputInnerWrapper>
                { input }
                <label htmlFor={name}>{label}{showIsRequired ? ' *' : ''}</label>
            </StyledInputInnerWrapper>
        </StyledInputContainer>
    )
}

export default FormInput;
