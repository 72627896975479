import DefaultPage from '../../05_templates/Default';
import React from 'react';
import styled from 'styled-components';
import Button from '../../02_atoms/Button';
import { ReactComponent as ButtonArrow} from '../../../icons/arrow-right-long.svg';
import { ReactComponent as PenIcon} from '../../../icons/pencil.svg';
import { ReactComponent as IdIcon} from '../../../icons/id.svg';
import { Context } from '../../00_utilities/context';
import urlFetch from '../../00_utilities/connectivity/urlFetch';
import HeadlessContentElements from "../../01_base/HeadlessContentElements";
import ComponentContainer from "../../01_base/layout/ComponentContainer";
import ContentContainer from "../../01_base/layout/ContentContainer";

const StyledStartWrapper = styled('div')`
    padding: 100px 17px 0 17px;

    ${ComponentContainer} {
        &:first-child {
            padding-top: 100px;
        }
        
        padding-left: 0;
        padding-right: 0;
    }
    
    ${ContentContainer} {
        max-width: 1200px;
    }
    
    .image-text-component__text-container {
        color: ${props => props.theme.colors.white};

        li::marker {
            color: ${props => props.theme.colors.pink};
        }
        
        strong {
            color: ${props => props.theme.colors.pink};
        }
    }
`;

const StyledContentWrapper = styled('div')`
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    margin: auto;

    @media (min-width: ${props => props.theme.breakpoints.xl.breakpoint}px) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const StyledTextElement = styled('div')`
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    
    .h1 {
        max-width: 780px;
        display: inline-block;
        font-size: 48px;

        @media (min-width: ${props => props.theme.breakpoints.md.breakpoint}px) {
            font-size: 56px;
        }
        
        p {
            font-size: inherit;
            line-height: inherit;
            margin: 0;
        }
    }
    
    .application-name {
        color: ${props => props.theme.colors.pink};
    }
    
    p {
        max-width: 740px;
        display: inline-block;
        margin-top: 0;
        
        p {
            margin: 0;
        }
    }

    @media (min-width: ${props => props.theme.breakpoints.xl.breakpoint}px) {
        margin-bottom: 0;
        min-width: 780px;
    }
`;

const StyledLoginElement = styled('div')`
    position: relative;
    z-index: 1;
    text-align: center;
    
    .button {
        font-size: 18px;
        letter-spacing: 0.015rem;
        white-space: nowrap;
    }
    
    p {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 13px;
        letter-spacing: 0.015rem;
        
        + p {
            margin-top: 5px;
        }
    }
`;

const StyledLoginContainer = styled('div')`
    width: 340px;
    letter-spacing: 0;
    
    .button {
        position: relative;
        width: 100%;
        font-size: 16px;
        letter-spacing: 0;
        box-sizing: border-box;
        padding: 14px;
        text-align: left;
        
        + .button {
            margin-top: 15px;
        }
        
        a {
            padding: 0;
            text-align: left;
            margin: 0;
        }
        
        a > svg {
            position: absolute;
            right: 15px;
            top: 21px;
        }
        
        a > span {
            display: inline-block;
        }
        
        span > svg {
            width: 20px;
            height: 20px;
            display: inline-block;
            position: relative;
            margin-right: 10px;
            margin-bottom: -3px;
        }
        
        &:first-child {
            span > svg {
                height: 28px;
                margin-bottom: -8px;
                margin-top: -2px;
                width: 23px;
                margin-right: 7px;
            }
        }
    }
    
    .login-container__head {
        background-color: ${props => props.theme.colors.whiteSmoke};
        color: ${props => props.theme.colors.black};
        text-align: left;
        font-weight: bold;
        padding: 25px 35px;
    }
    
    .login-container__body {
        background-color: ${props => props.theme.colors.white};
        padding: 35px;
        
        hr {
            margin-top: 25px;
            margin-bottom: 25px;
            border: none;
            border-bottom: 2px solid ${props => props.theme.colors.tableGrey};
        }
    }
    
    .login-container__link-container {
        text-align: left;
        
        a {

            svg {
                width: 18px;
                height: 10px;
                padding-left: 5px;
                fill: ${props => props.theme.colors.pink};

                path {
                    fill: ${props => props.theme.colors.pink};
                }
            }
        }
    }
`;

const StyledBackgroundImage = styled('div')`
    img {
        position: absolute;
        object-fit: cover;
        height: calc(100% + 50px);
        width: 100%;
        top: 0;
        left: 0;
        object-position: top left;
        margin-top: -50px;
    }
`;

function Start() {
    const context = React.useContext(Context);
    const loginUrlBrainlabId = context.loginUrls && context.loginUrls.brainlab_id ? context.loginUrls.brainlab_id : null;
    const loginUrlAzureAd = context.loginUrls && context.loginUrls.azure_ad ? context.loginUrls.azure_ad : null;
    const [headlessContentLoaded, setHeadlessContentLoaded] = React.useState(false);
    const [headlessContent, setHeadlessContent] = React.useState(null);

    React.useEffect(() => {
        urlFetch('/api/headless-content/start', { successCallback }, null, 'POST', null, { email: context.userEmail });
    }, [headlessContentLoaded]); // eslint-disable-line

    const successCallback = (response) => {
        setHeadlessContentLoaded(true);
        setHeadlessContent(response);
    }

    const headline = headlessContent && headlessContent.headline ? headlessContent.headline : '';
    const text = headlessContent && headlessContent.text ? headlessContent.text : '';
    const image = headlessContent && headlessContent.image ? headlessContent.image : '';
    const registerUrl = headlessContent && headlessContent.register_url ? headlessContent.register_url : '';

    return (
        <DefaultPage backgroundImage={<StyledBackgroundImage dangerouslySetInnerHTML={{ __html: image }}/>}>
            <StyledStartWrapper>
                <StyledContentWrapper>
                    <StyledTextElement>
                        <div className='h1' dangerouslySetInnerHTML={{ __html: headline }}/>
                        <p dangerouslySetInnerHTML={{ __html: text }}/>
                    </StyledTextElement>

                    <StyledLoginElement>
                        <StyledLoginContainer>
                            <div className='login-container__head'>Select login option</div>
                            <div className='login-container__body'>
                                <Button>
                                    <a href={loginUrlBrainlabId}><span><IdIcon/></span> Login with Brainlab ID <ButtonArrow/></a>
                                </Button>
                                <Button inverted>
                                    <a href={registerUrl}><span><PenIcon/></span> Register for Brainlab ID <ButtonArrow/></a>
                                </Button>

                                <hr/>

                                <div className='login-container__link-container'>
                                    <a href={loginUrlAzureAd}>Login as Employee <ButtonArrow/></a>
                                </div>
                            </div>
                        </StyledLoginContainer>
                    </StyledLoginElement>
                </StyledContentWrapper>
                <HeadlessContentElements headlessContent={headlessContent} additionalProps={{registerUrl: registerUrl}} withComponentContainer/>
            </StyledStartWrapper>

        </DefaultPage>
    );
}

export default Start;
