import urlFetch from '../connectivity/urlFetch';

export function upsertToCartObject(productId, count, context) {
    count = parseInt(count);

    const newCart = Object.assign({}, context.cart);
    const items = Object.assign({}, newCart.items);

    items[productId] = {
        product_id: productId,
        count: count
    };

    newCart.items = items;

    if (count === 0) {
        delete newCart.items[productId];
    }

    return newCart;
}

export default function updateCart(productId, count, context) {
    const data = upsertToCartObject(productId, count, context);

    urlFetch('/api/carts/update', { successCallback: context.updateCart }, null, 'POST', null, { email: context.userEmail, data }, context.token);
}
