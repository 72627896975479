import React from "react";
import styled from "styled-components";
import urlFetch from "../../00_utilities/connectivity/urlFetch";
import QuoteCheckoutInformation from "../../03_molecules/QuoteCheckoutInformation";
import Button from "../../02_atoms/Button";
import {Link} from "react-router-dom";
import CheckIcon from "../../../icons/save-white.svg";
import {Context} from "../../00_utilities/context";

const StyledSubmissionForm = styled('div')`
    width: 100%;
    background-color: ${props => props.theme.colors.whiteSmoke};
    padding: 30px 20px;
    box-sizing: border-box;

    @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
        max-width: 410px;
    }
    
    .checkout-submission-form__buttons-container {
        text-align: right;
        margin-top: 20px;

        .button {
            padding: 20px;
            box-sizing: border-box;
            
            .interactive-link {
                margin: -20px;
                padding: 20px;
                width: 100%;
                display: inline-block;
            }
        }

        @media (min-width: ${props => props.theme.breakpoints.xxl.breakpoint}px) {
            text-align: center;
            
            .button {
                width: 100%;
            }
        }
    }
`;

const StyledCheckboxContainer = styled('div')`
    margin-top: 20px;

    input[type='checkbox'] {
        position: absolute;
        width: 14px;
        height: 14px;
        margin: 0;
        padding: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;

        + label {
            position: relative;
            padding-left: 30px;

            &::before {
                position: absolute;
                left: 0;
                top: 4px;
                display: block;
                width: 14px;
                height: 14px;
                content: '';
                background: white;
                border: 1px solid ${props => props.theme.colors.randomGrey};
                border-radius: 3px;
            }
        }

        &:hover,
        &:active {
            + label {
                cursor: pointer;

                &::before {
                    border: 1px solid ${props => props.theme.colors.darkGrey};
                }
            }
        }

        &:checked + label {
            &::before {
                background: ${props => props.theme.colors.randomGrey};
            }

            &::after {
                position: absolute;
                content: '';
                top: 6px;
                left: 2px;
                display: block;
                width: 12px;
                height: 12px;
                background: url(${CheckIcon}) no-repeat 0 0;
                background-size: 12px;
            }
        }
    }
`;

function CheckoutSubmissionForm(props) {
    const { setCheckoutSuccess, setOrderNumber} = props;
    const [isQuoteInformationComplete, setIsQuoteInformationComplete] = React.useState(false);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [quoteInformation, setQuoteInformation] = React.useState({});
    const [loadingCheckout, setLoadingCheckout] = React.useState(false);
    const context = React.useContext(Context);

    const checkout = () => {
        setLoadingCheckout(true);

        urlFetch('/api/carts/checkout', { successCallback: (response) => {
                context.updateCart({});
                setLoadingCheckout(false);
                setCheckoutSuccess(true);
                setOrderNumber(response.order.number);
            } }, null, 'POST', null, {
            email: context.userEmail,
            quoteInformation
        }, context.token);
    }

    const updateQuoteInformation = (e) => {
        const {name, value} = e.currentTarget;
        const newQuoteInformation = Object.assign({}, quoteInformation);

        newQuoteInformation[name] = value;
        setQuoteInformation(newQuoteInformation);
        updateIsQuoteInformationComplete(newQuoteInformation);
    }

    const getQuoteInformationValueByKey = (key) => {
        return key in quoteInformation ? quoteInformation[key] : '';
    }

    const updateIsQuoteInformationComplete = (quoteInformation) => {
        const isNotEmpty = Object.keys(quoteInformation).length > 0;
        const hasRequiredKeys = 'quoteEmail' in quoteInformation && 'quoteContactPerson' in quoteInformation;
        const hasRequiredValues = /^\S+@\S+\.\S+$/.test(quoteInformation.quoteEmail) && quoteInformation.quoteContactPerson !== '';

        const complete = isNotEmpty && hasRequiredKeys && hasRequiredValues;
        setIsQuoteInformationComplete(complete);
    }

    let submitOrderButtonText = 'Send quote request';
    submitOrderButtonText = termsAccepted ? submitOrderButtonText : 'Accept terms & conditions first'
    submitOrderButtonText = !isQuoteInformationComplete ? 'Complete recipient first' : submitOrderButtonText;


    return <StyledSubmissionForm>
        <h3>Quote Recipient</h3>
        <QuoteCheckoutInformation changeHandler={updateQuoteInformation} getValueByKey={getQuoteInformationValueByKey}/>

        <StyledCheckboxContainer>
            <div>
                Information on the processing of your personal data can be found in the Brainlab <a href='/content/privacy-policy' target='_blank'>privacy policy</a>.
            </div>

            <br/>

            <div>
                <input id='checkbox-terms' type='checkbox' key='checkbox-terms' name='checkbox-terms' checked={termsAccepted} onChange={() => { setTermsAccepted(!termsAccepted); }}/>
                <label htmlFor='checkbox-terms'>I accept the <a href='/content/terms' target='_blank'>terms & conditions</a> *</label>
            </div>
        </StyledCheckboxContainer>

        <div className='checkout-submission-form__buttons-container'>
            { (termsAccepted && isQuoteInformationComplete) &&
                <Button loading={loadingCheckout}>
                    <span className='interactive-link' onClick={checkout}>{submitOrderButtonText}</span>
                </Button>
            }
            { (!termsAccepted || !isQuoteInformationComplete) &&
                <Button disabled>
                    <span className='interactive-link'>{submitOrderButtonText}</span>
                </Button>
            }
            { !context.customerIsComplete &&
                <Button>
                    <Link to='/profile'>Complete profile first</Link>
                </Button>
            }
        </div>
    </StyledSubmissionForm>
}

export default CheckoutSubmissionForm;
