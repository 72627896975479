import React from "react";
import styled from 'styled-components';
import Popup from '../../02_atoms/Popup';
import Button from '../../02_atoms/Button';
import { ReactComponent as CartIcon } from '../../../icons/cart.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../icons/arrow-left.svg';
import {Link} from "react-router-dom";

const StyledBodyContainer = styled('div')`
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
        flex-direction: row;
    }
`;

const StyledContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledButtonsContainer = styled('div')`
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .button {
        padding: 15px 15px 14px 15px;

        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            padding: 15px 18px 14px 18px;
        }

        svg {
            width: 18px;
            height: 16px;
            margin-bottom: -2px;
            margin-left: 2px;
        }
    }
`;

const StyledBackLink = styled('a')`
    font-size: 12px;
    
    &:hover {
        cursor: pointer;
    }
    
    svg {
        width: 15px;
        height: 10px;
        fill: ${props => props.theme.colors.pink};
    }
`;

const ReorderCheckPopup = (props) => {
    const { message, setMessage, preparedReorder, setPreparedReorder, reorderAvailableProducts } = props;

    const setShowPopup = (popupActive) => {
        if (popupActive) {
            return;
        }

        setPreparedReorder(null);
        setMessage(null);
    }

    return (
        <Popup setShowPopup={setShowPopup}>
            <StyledBodyContainer>
                <StyledContentContainer>
                    {
                        message && <div dangerouslySetInnerHTML={{ __html: message }}/>
                    }

                    <StyledButtonsContainer>
                        <StyledBackLink onClick={() => { setPreparedReorder(null); setMessage(null);  }}><ArrowLeftIcon/> View history</StyledBackLink>

                        {
                            preparedReorder != null &&
                            <Button clickHandler={() => { reorderAvailableProducts(preparedReorder); }}>Reorder available products</Button>
                        }

                        {
                            preparedReorder === null &&
                            <Button clickHandler={() => { setMessage(null); }}>
                                <Link to='/'>Continue product selection <CartIcon/></Link>
                            </Button>
                        }
                    </StyledButtonsContainer>
                </StyledContentContainer>
            </StyledBodyContainer>
        </Popup>
    )
}

export default ReorderCheckPopup;
