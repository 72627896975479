import React from "react";
import styled from "styled-components";

const StyledProductItemsTableContainer = styled('div')`
    position: relative;
    overflow-x: auto;
`;

const StyledProductItemsTable = styled('table')`
    width: 100%;
    min-width: ${props => props.theme.breakpoints.md.breakpoint}px;
    border-collapse: collapse;
    margin-bottom: 60px;
    
    thead {
        td:not(td:nth-child(5)),
        td:nth-child(5) {
            padding-top: 0;
        }
        
        td:nth-child(4) { // price
            text-align: left;
        }
    }
    
    tbody {
        border-top: 2px solid ${props => props.theme.colors.midGrey};
        border-bottom: 2px solid ${props => props.theme.colors.midGrey};
    }
    
    td:nth-child(1) { // art nr
        width: 10%;
    }
    td:nth-child(2) { // gtin
        width: 15%;
    }
    td:nth-child(3) { // product name
        width: 40%;
    }
    td:nth-child(4) { // price
        width: 15%;
        text-align: right;
        
        span {
            white-space: nowrap;
        }
    }
    td:nth-child(5) { // interaction
        width: 20%;
        text-align: right;
        padding: 5px 10px 5px 5px;
        
        > div {
            white-space: nowrap;
        }

        div + div {
            margin-left: 5px;
        }
    }
    
    td:not(td:nth-child(5)) {
        padding: 10px;
    }
    
    .column-title-addition {
        font-size: 12px;
    }
    
    tbody td {
        @media (min-width: ${props => props.theme.breakpoints.lg.breakpoint}px) {
            font-size: 18px;
        }
    }
    
    tbody tr:nth-child(odd) td {
        background-color: ${props => props.theme.colors.tableGrey};
    }
    
    .quantity-select-container {
        width: 55px;
        height: 40px;
        display: inline-block;
        vertical-align: top;
        
        &::before {
            left: 7px;
            font-size: 10px;
        }
        
        select {
            height: 100%;
            font-size: 14px;
            padding: 16px 0 0 7px;
            background-size: 12px;
        }
    }
    
    .button {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;
        height: 40px;
        padding: 7px;
        align-items: center;
        
        .interactive-link {
            padding: 7px;
            margin: 0 -7px;
        }
    }
`;


const ProductItemsTable = (props) => {
    const taxString = '(per packaging unit) without VAT';

    return (
        <StyledProductItemsTableContainer>
            <StyledProductItemsTable>
                <thead>
                <tr>
                    <td>Art. Nr.</td>
                    <td>GTIN</td>
                    <td>Product name</td>
                    <td>
                        <div>Price</div>
                        <div className="column-title-addition">{taxString}</div>
                    </td>
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {props.children}
                </tbody>
            </StyledProductItemsTable>
        </StyledProductItemsTableContainer>
    )
};

export default ProductItemsTable;
