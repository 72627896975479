import React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { ReactComponent as Logo} from '../../../icons/logo.svg';
import { ReactComponent as UserIcon} from '../../../icons/user.svg';
import { ReactComponent as CartIcon} from '../../../icons/cart.svg';
import { ReactComponent as LogoutIcon} from '../../../icons/logout.svg';
import { Context } from '../../00_utilities/context';
import cssLock from "../../00_utilities/mixins/cssLock";

const StyledHeader = styled('header')`
    top: 0;
    height: 50px;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    font-size: 15px;
    background-color: ${props => props.theme.colors.pink};
    z-index: 6;
    pointer-events:  ${props => props.popupActive ? 'none' : 'all'};

    ${props => {
        if (props.popupActive) {
            return `
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(33,33,33,0.5);
                    content: '';
                }
            `
        }
    }}
`;

const StyledHeaderLink = styled(Link)`
    color: white;
    margin-left: 20px;
    position: relative;
        
    &:hover {
        cursor: pointer;
     }
    
    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        &::after {
            position: absolute;
            top: -4px;
            right: 15px;
            height: 25px;
            content: '';
            border-right: 1px solid white;
        }
    }
`;

const StyledHeaderTitle = styled('span')`
    margin-top: -2px;
    display: none;
    
    @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
        display: block;
    }
`;

const StyledLogo = styled(Logo)`
    width: 120px;
    height: 16px;
    fill: white;
    margin-right: 30px;
`;

const StyledMetaNavigation = styled('div')`
    position: absolute;
    ${props => cssLock('right', props.theme.breakpoints.sm.breakpoint, props.theme.breakpoints.xxl.breakpoint, 15, 240)};
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.white};
    
    a {
        color: ${props => props.theme.colors.white};
    }
`;

const StyledProfileIconContainer = styled('div')`
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: background-color 500ms;

    svg {
        fill: white;
        height: 25px;
        width: 25px;
        margin-top: -1px;
        transition: fill 500ms, transform 500ms;
        
        path:nth-child(2) {
            transition: opacity 500ms;
        }
    }
    
    &.activated {
        background-color: yellow;
        
        svg {
            fill: black;
            transform: rotate(-90deg);
            
            path:nth-child(2) {
                opacity: 0;
            }
        }
    }
`;

const StyledProfileLink = styled(Link)`
    display: flex;
    align-items: center;
    
    span {
        margin-right: 10px;
        display: none;

        @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
            display: inline-block;
        }
    }
    
    &:hover {
        cursor: pointer;
    }
    
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
`;

const StyledRequestsLink = styled(Link)`
    .requests-link-title--mobile {
        display: inline-block;

        @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
            display: none;
        }
    }
    
    .requests-link-title--desktop {
        display: none;

        @media (min-width: ${props => props.theme.breakpoints.sm.breakpoint}px) {
            display: inline-block;
        }
    }
`;

const StyledCartElement = styled(Link)`
    text-decoration: none;
    position: relative;
    margin-right: 2px;
    
    svg {
        fill: white;
        height: 25px;
        width: 25px;
    }
    
    .cart-element__counter {
        display: flex;
        min-width: 11px;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -4px;
        right: -8px;
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.pink};
        padding: 2px;
        font-size: 11px;
        line-height: 11px;
        border-radius: 7px;
    }
`;

const StyledCartIconContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledSeparator = styled('div')`
    width: 1px;
    height: 30px;
    opacity: 0.5;
    background-color: ${props => props.theme.colors.white};
    margin-right: 10px;
    margin-left: 10px;
`;

const StyledLogoutElement = styled('div')`
    display: flex;
    align-items: center;
    margin-left: 1px;
    
    &:hover {
        cursor: pointer;
    }
    
    svg {
        height: 18px;
        width: 18px;
        
        path {
            fill: white;
        }
    }
`;

function Header(props) {
    const context = React.useContext(Context);
    const hasFirstName = !!context.customer.firstName && context.customer.firstName !== '';
    const { isPublicPage } = context;
    const { popupActive } = props;
    let time = 0;

    const sumItems = (items) => {
        const sumIncludingShoppingCostProduct = Object.values(items).reduce((previousItem, currentItem) => {
            return previousItem + parseInt(currentItem.count, 10);
        }, 0);

        return sumIncludingShoppingCostProduct === 0 ? 0 : sumIncludingShoppingCostProduct - 1;
    }

    const startTimer = () => {
        time = (new Date()).getTime();
    }

    const stopTimer = () => {
        const style = document.getElementsByTagName('html')[0].style;
        const stopTime = (new Date()).getTime();
        time = Math.abs((stopTime - time)/1000);

        if (time > 3 && time < 6) {
                document.querySelector('.profile-icon-container').classList.add('activated');
        }

        if (time > 6 && time < 9) {
            style.filter = style.filter !== '' ? '' : 'hue-rotate(225deg)';
        }

        if (time > 9) {
            style.filter = '';
            document.querySelector('.profile-icon-container').classList.remove('activated');
        }

        time = 0;
    }

    const counterText = context.cart.items && sumItems(context.cart.items) !== 0 ? sumItems(context.cart.items) : '';
    const logoLink = context.isPublicPage ? context.startPagePath : '/';

    return (
        <StyledHeader popupActive={popupActive} onMouseDown={startTimer} onMouseUp={stopTimer}>
            <StyledHeaderLink to={logoLink} aria-label='Start page or product list page when logged in'>
                <StyledLogo/>
            </StyledHeaderLink>
            <StyledHeaderTitle>QREO</StyledHeaderTitle>

            {
                !isPublicPage &&
                <>
                    <StyledMetaNavigation>
                        <StyledProfileLink to='/profile'>
                            { hasFirstName && <span>Hello {context.customer.firstName}</span>}
                            <StyledProfileIconContainer className={'profile-icon-container'}><UserIcon/></StyledProfileIconContainer>
                        </StyledProfileLink>

                        <StyledSeparator/>

                        <StyledRequestsLink to='/orders'>
                            <span className='requests-link-title--mobile'>Requests</span>
                            <span className='requests-link-title--desktop'>Request History</span>
                        </StyledRequestsLink>

                        <StyledSeparator/>

                        <StyledCartElement to='/cart'>
                            <StyledCartIconContainer>
                                <CartIcon/>
                                { counterText !== '' &&
                                    <div className='cart-element__counter'>
                                        <span>{counterText}</span>
                                    </div>
                                }
                            </StyledCartIconContainer>
                        </StyledCartElement>

                        <StyledSeparator/>

                        <StyledLogoutElement onClick={context.logout}><LogoutIcon/></StyledLogoutElement>
                    </StyledMetaNavigation>
                </>
            }
        </StyledHeader>
    );
}

export default Header;
