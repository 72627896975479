function placeGermanThousandSeparators(numberString) {
    return numberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

module.exports = (priceFloat) => {
    const fixedPointNotationString = priceFloat.toFixed(2).replace('.', ',');

    return placeGermanThousandSeparators(fixedPointNotationString);
};

